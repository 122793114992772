import React from 'react'
import {
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient,
  FormulaNutritionFactNutrientLabelDisplay
} from 'models/FormulaLabel'
import { ItemTableContent } from '../../components/ItemTableContent'
import { NutritionFactsRule } from '../../components/NutritionFactsRule'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import {
  NurientContainer,
  NutrientTableContainer,
  NutrientsTableHeaderContainer
} from '../NutritionLabelTabularStyles'

export const getSplitTopNutrientsTable = ({
  topNutrients,
  startIndex,
  showDvPercentageStar
}: {
  topNutrients: FormulaNutritionFactNutrientLabelDisplay[]
  startIndex: number
  showDvPercentageStar: boolean
}) => (
  <NutrientTableContainer>
    <NutrientsTableHeaderContainer>
      <NutritionLabelText bold points={6}>
        Amount/serving
      </NutritionLabelText>
      <NutritionLabelText bold points={6}>
        % Daily Value{showDvPercentageStar ? '*' : ''}
      </NutritionLabelText>
    </NutrientsTableHeaderContainer>
    <NutritionFactsRule type="normal" margin={2} />
    <ItemTableContent
      key={`topNutrients-${startIndex}`}
      tableItemRowsProps={Array(Math.ceil(topNutrients.length / 2))
        .fill(null)
        .map((_, index) => {
          const fnfn = topNutrients[startIndex + index]
          return {
            nutrientType: fnfn?.nutrient.type,
            itemRowValues: {
              name:
                fnfn?.displayNameOptions[
                  FormulaNutritionFactLanguage.ENGLISH
                ] || '',
              amount: fnfn?.displayAmountOptions[0]?.amount,
              unit: fnfn?.nutrient.unit,
              dvPercentage: fnfn?.displayDvOptions[0]?.amount
            }
          }
        })}
    />
    <NutritionFactsRule type="normal" margin={2} />
  </NutrientTableContainer>
)

export const TopNutrientsTable: React.FC<{
  topNutrients: FormulaNutritionFactNutrientLabelDisplay[]
  showDvPercentageStar?: boolean
}> = ({ topNutrients = [], showDvPercentageStar = true }) => (
  <NurientContainer>
    {getSplitTopNutrientsTable({
      topNutrients,
      startIndex: 0,
      showDvPercentageStar: showDvPercentageStar
    })}
    {getSplitTopNutrientsTable({
      topNutrients,
      startIndex: Math.ceil(topNutrients.length / 2),
      showDvPercentageStar: showDvPercentageStar
    })}
  </NurientContainer>
)
