import {
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient,
  FormulaNutritionFactNutrientLabelDisplay
} from 'models/FormulaLabel'
import { ItemTableContent } from './ItemTableContent'

export const TopNutrientsTable: React.FC<{
  topNutrients: FormulaNutritionFactNutrientLabelDisplay[]
  showDvPercentageStar?: boolean
}> = ({ topNutrients = [] }) => (
  <>
    <ItemTableContent
      key={`topNutrients-${0}`}
      tableItemRowsProps={topNutrients.map((fnfn) => {
        return {
          nutrientType: fnfn.nutrient.type,
          itemRowValues: {
            name:
              Object.keys(fnfn.displayNameOptions).length > 1
                ? `${
                    fnfn.displayNameOptions[
                      FormulaNutritionFactLanguage.ENGLISH
                    ]
                  } / ${
                    fnfn.displayNameOptions[FormulaNutritionFactLanguage.FRENCH]
                  }`
                : `${
                    fnfn.displayNameOptions[
                      FormulaNutritionFactLanguage.ENGLISH
                    ]
                  }`,
            amount: fnfn.displayAmountOptions[0]?.amount,
            unit: fnfn.nutrient.unit,
            dvPercentage: fnfn.displayDvOptions[0]?.amount
          }
        }
      })}
    />
  </>
)
