import React from 'react'
import { NutritionFactsProps } from '../NutritionLabel'
import { NutritionLabelVerticalBox } from '../NutritionLabelVertical/NutritionLabelVerticalStyles'
import { NutritionFactsRule } from '../components/NutritionFactsRule'
import {
  Container,
  FlexContainerColumn,
  FlexContainerColumnSpaced,
  FlexContainerRowSpacedTop
} from '../components/NutritionLabelStyles'
import { NutritionLabelTextCanadian } from '../components/NutritionLabelTextCanadian'
import { BottomNutrientsTable } from './components/BottomNutrientsTable'
import { TopNutrientsTable } from './components/TopNutrientsTable'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'

export const NutritionLabelVerticalCanadian: React.FC<NutritionFactsProps> = ({
  servingSize,
  servingWeight,
  topNutrients,
  bottomNutrients,
  calories
}) => {
  // In Canadian Regulation, leading = stated leading - font size.

  return (
    <Container>
      <NutritionLabelVerticalBox>
        <FlexContainerColumnSpaced>
          <NutritionLabelTextCanadian bold points={13}>
            Nutrition Facts
          </NutritionLabelTextCanadian>
          <NutritionLabelTextCanadian bold points={13} additionalLineHeight={2}>
            Valeur nutritive
          </NutritionLabelTextCanadian>
          <NutritionLabelTextCanadian points={9} additionalLineHeight={2}>
            Per{' '}
            {
              servingSize[
                FormulaNutritionFactLanguage.ENGLISH as keyof typeof servingSize
              ]
            }{' '}
            ({servingWeight})
          </NutritionLabelTextCanadian>
          <NutritionLabelTextCanadian points={9}>
            pour{' '}
            {
              servingSize[
                FormulaNutritionFactLanguage.FRENCH as keyof typeof servingSize
              ]
            }{' '}
            ({servingWeight})
          </NutritionLabelTextCanadian>
        </FlexContainerColumnSpaced>

        <NutritionFactsRule type="normal" regulation="canadian" margin={1.75} />

        <FlexContainerRowSpacedTop>
          <Container>
            <NutritionLabelTextCanadian bold points={10}>
              Calories {calories}
            </NutritionLabelTextCanadian>
            <NutritionFactsRule type="thick" regulation="canadian" margin={1} />
          </Container>
          <FlexContainerColumn>
            <NutritionLabelTextCanadian bold alignRight points={6}>
              % Daily Value
              <NutritionLabelTextCanadian symbol={true} points={9}>
                *
              </NutritionLabelTextCanadian>
            </NutritionLabelTextCanadian>

            <NutritionLabelTextCanadian bold alignRight points={6}>
              % valeur quotidienne
              <NutritionLabelTextCanadian symbol={true} points={9}>
                *
              </NutritionLabelTextCanadian>
            </NutritionLabelTextCanadian>
          </FlexContainerColumn>
        </FlexContainerRowSpacedTop>

        <TopNutrientsTable topNutrients={topNutrients} />
        <NutritionFactsRule type="thick" regulation="canadian" margin={0.5} />

        <BottomNutrientsTable bottomNutrients={bottomNutrients} />

        <NutritionFactsRule type="thick" regulation="canadian" margin={0.5} />
        <FlexContainerColumn>
          <NutritionLabelTextCanadian points={6.5} additionalLineHeight={1}>
            *5% or less is <b>a little</b>, 15% or more is <b>a lot</b>
          </NutritionLabelTextCanadian>
          <NutritionLabelTextCanadian points={6.5} additionalLineHeight={1}>
            *5% ou moins c'est <b>peu</b>, 15% ou plus c'est <b>beaucoup</b>
          </NutritionLabelTextCanadian>
        </FlexContainerColumn>
      </NutritionLabelVerticalBox>
    </Container>
  )
}
