import React from 'react'
import { InputFieldLabel } from 'components/InputFieldLabel/InputFieldLabel'
import {
  SelectOption,
  SelectOptionsLabel
} from 'components/SelectOptionsLabel/SelectOptionsLabel'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import {
  BoxRow,
  ViewerContainer,
  ViewerContent,
  ViewerRow
} from 'components/common'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import {
  AutoCompleteLabel,
  SelectionAction
} from 'components/AutoCompleteLabel2/AutoCompleteLabel2'
import { ModalContext } from 'components/Modal/ModalContext'
import {
  SavingStatus,
  SavingStatusIndicator
} from 'components/SavingStatusIndicator/SavingStatusIndicator'
import { BasicAllergen } from 'models/Allergen'
import { DetailedSimpleIngredient } from 'models/SimpleIngredient'
import { BasicSupplier } from 'models/Supplier'
import { BasicTag } from 'models/Tags'
import {
  addSimpleIngredientAllergen,
  addSimpleIngredientTag,
  addSimpleIngredientToSupplier,
  createSimpleIngredient,
  deleteSimpleIngredient,
  deleteSimpleIngredientAllergen,
  deleteSimpleIngredientDensityOverride,
  deleteSimpleIngredientFromSupplier,
  deleteSimpleIngredientTag,
  getSimpleIngredient,
  getSimpleIngredientAllergens,
  getSimpleIngredientSuppliers,
  setSelectedSimpleIngredient,
  updateSimpleIngredient
} from 'state/simple_ingredients/SimpleIngredientsSlice'
import { getSuppliers } from 'state/suppliers/SuppliersSlice'
import { getCompanyTags } from 'state/tags/TagsSlice'
import { IngredientViewerPanel } from './IngredientViewerPanel'
import { Button } from 'components/Button/Button'
import { FilePreviewContext } from 'components/FilePreview/FilePreviewContext'
import { getMeasurements } from 'state/simple_ingredients/measurements/SimpleIngredientMeasurementsSlice'
import { IngredientCost } from './components/IngredientCost/IngredientCost'
import { PageViewerContext } from '../PageViewerContext'
import { Clear } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'

interface IngredientViewerProps {
  show: boolean
  simpleIngredient?: DetailedSimpleIngredient
  allergenOptions: SelectOption[]
  supplierOptions: SelectOption[]
  tagOptions: SelectOption[]
  onSimpleIngredientUpdate: (
    simpleIngredient?: DetailedSimpleIngredient,
    refresh?: boolean
  ) => void
  onSelectedSimpleIngredientDuplicate: () => void
  readOnly?: boolean
}

export const IngredientViewer: React.FC<IngredientViewerProps> = ({
  show = false,
  simpleIngredient: ingredient,
  allergenOptions,
  supplierOptions,
  tagOptions,
  onSimpleIngredientUpdate: onIngredientUpdate,
  onSelectedSimpleIngredientDuplicate,
  readOnly = false
}) => {
  const dispatch = useAppDispatch()
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showError, showSuccess } = React.useContext(SnackbarContext)
  const { setFilePreviewLeftTargetRef } = React.useContext(FilePreviewContext)

  const formRef = React.useRef<HTMLFormElement>(null)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const creating = useAppSelector((state) => state.simpleIngredients.creating)
  const updating = useAppSelector((state) => state.simpleIngredients.updating)
  const adding = useAppSelector((state) => state.simpleIngredients.adding)
  const removing = useAppSelector((state) => state.simpleIngredients.removing)
  const { ingredientsAnalytics } = React.useContext(AnalyticsContext)

  const selectedSimpleIngredientId = useAppSelector(
    (state) => state.simpleIngredients.selectedSimpleIngredient?.id
  )

  const currentSimpleIngredientAllergens = useAppSelector(
    (state) => state.simpleIngredients.currentSimpleIngredientAllergens
  )
  const measurements = useAppSelector(
    (state) => state.simpleIngredientMeasurementSlice.measurements
  )
  const [currentAllergens, setCurrentAllergens] = React.useState<
    BasicAllergen[]
  >([])

  const [currentSimpleIngredient, setCurrentSimpleIngredient] =
    React.useState<DetailedSimpleIngredient>()

  const [currentIngredientSuppliers, setCurrentIngredientSuppliers] =
    React.useState<BasicSupplier[]>([])
  const [tags, setCurrentTags] = React.useState<BasicTag[]>([])
  const [hasChanges, setHasChanges] = React.useState(false)
  const [valid, setValid] = React.useState(false)
  const saving = creating || updating || adding || removing

  const [ingredientSupplierOptions, setIngredientSupplierOptions] =
    React.useState<SelectOption[]>([])

  const [ingredientTagOptions, setIngredientTagOptions] = React.useState<
    SelectOption[]
  >([])

  const ref = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    if (ref && ref.current) {
      setFilePreviewLeftTargetRef(ref)
    }
  }, [ref.current])

  React.useEffect(() => {
    if (currentSimpleIngredient && !measurements?.length) {
      void dispatch(
        getMeasurements({
          companyId: currentCompany.id
        })
      )
    }
  }, [currentSimpleIngredient?.id])

  React.useEffect(() => {
    // Create a set of existing option values for faster lookup.
    const existingOptionValues = new Set(
      supplierOptions.map((option) => option.value)
    )

    // Filter the new options to include only those not already present.
    const newOptionsFromSuppliers = currentIngredientSuppliers
      .filter((supplier) => !existingOptionValues.has(supplier.id))
      .map((supplier) => ({ value: supplier.id, label: supplier.name }))

    // Combine the existing options with the new, filtered options.
    const newOptions = [...supplierOptions, ...newOptionsFromSuppliers]

    // Update the state with the combined options.
    setIngredientSupplierOptions(newOptions)
  }, [supplierOptions, currentIngredientSuppliers])

  React.useEffect(() => {
    // Create a set of existing option values for faster lookup.
    const existingOptionValues = new Set(
      tagOptions.map((option) => option.value)
    )

    // Filter the new options to include only those not already present.
    const newOptionsFromTags = tags
      .filter((tag) => !existingOptionValues.has(tag.id))
      .map((tag) => ({ value: tag.id, label: tag.name }))

    // Combine the existing options with the new, filtered options.
    const newOptions = [...tagOptions, ...newOptionsFromTags]

    // Update the state with the combined options.
    setIngredientTagOptions(newOptions)
  }, [tagOptions, tags])

  const createNewIngredient = (
    name: string,
    statement: string,
    verified: boolean,
    friendlyId: string,
    manufacturer: string,
    description: string,
    isSugar: boolean
  ) => {
    if (ingredient) {
      ingredientsAnalytics.createdNewIngredient()
      void dispatch(
        createSimpleIngredient({
          companyId: currentCompany.id,
          name,
          statement,
          verified,
          friendlyId,
          manufacturer,
          description,
          isSugar
        })
      )
        .unwrap()
        .then((payload) => {
          onIngredientUpdate(
            {
              ...payload,
              tags: [],
              formulas: [],
              suppliers: [],
              verified: false,
              ingredientStatement: '',
              manufacturer: '',
              description: '',
              isSugar: false
            },
            true
          )
        })
        .catch(() => {
          setValid(false)
          setHasChanges(false)
          setCurrentSimpleIngredient({ ...ingredient })
          showError(`Failed to create new ingredient, ${name}.`)
        })
    }
  }

  const updateCurrentIngredient = (
    name: string,
    statement: string,
    verified: boolean,
    friendlyId: string,
    manufacturer: string,
    description: string,
    isSugar: boolean,
    densityOverride?: number
  ) => {
    if (ingredient) {
      void dispatch(
        updateSimpleIngredient({
          companyId: currentCompany.id,
          simpleIngredientId: ingredient.id,
          name,
          statement,
          verified,
          friendlyId,
          manufacturer,
          description,
          isSugar,
          densityOverride
        })
      ).catch(() => {
        setCurrentSimpleIngredient({ ...ingredient })
        showError(`Failed to update ${name}.`)
      })
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const name = formData.get('name') as string
    const statement = formData.get('statement') as string
    const verified = formData.get('verified') === '1' ? true : false
    const friendlyId = formData.get('friendlyId') as string
    const manufacturer = formData.get('manufacturer') as string
    const description = formData.get('description') as string
    const isSugar = formData.get('isSugar') === '1' ? true : false

    createNewIngredient(
      name,
      statement,
      verified,
      friendlyId,
      manufacturer,
      description,
      isSugar
    )
  }

  const handleFormChange = () => {
    if (formRef.current && currentSimpleIngredient) {
      const formData = new FormData(formRef.current)
      const name = (formData.get('name') as string) || ''
      const statement = (formData.get('statement') as string) || ''
      const verified = formData.get('verified') === '1' ? true : false
      const friendlyId = (formData.get('friendlyId') as string) || ''
      const manufacturer = (formData.get('manufacturer') as string) || ''
      const description = (formData.get('description') as string) || ''
      const isSugar = formData.get('isSugar') === '1' ? true : false
      const validity = Boolean(name)
      const densityOverride = parseFloat(
        formData.get('densityOverride') as string
      )
      const update = {
        ...currentSimpleIngredient,
        name,
        statement,
        verified,
        friendlyId,
        manufacturer,
        description,
        isSugar,
        densityOverride: isNaN(densityOverride)
          ? ingredient?.densityOverride
          : densityOverride
      }
      setCurrentSimpleIngredient(update)
      // if creating new ingredient set valid & hasChanges
      if (!currentSimpleIngredient.id) {
        setValid(validity)
        setHasChanges(
          [
            update.name !== ingredient?.name,
            update.statement !== ingredient?.ingredientStatement,
            update.verified !== ingredient?.verified,
            update.friendlyId !== ingredient?.friendlyId,
            update.manufacturer !== ingredient?.manufacturer,
            update.description !== ingredient?.description,
            update.isSugar !== ingredient?.isSugar,
            update.densityOverride !== ingredient?.densityOverride
          ].reduce((prev, cur) => (!prev ? cur : prev), false)
        )
      }
      // if updating existing ingredient update automatically
      if (currentSimpleIngredient.id && validity) {
        updateCurrentIngredient(
          name,
          statement,
          verified,
          friendlyId,
          manufacturer,
          description,
          isSugar,
          densityOverride
        )
      }
    }
  }

  const handleSelectChange = () => {
    setTimeout(() => {
      handleFormChange()
    }, 200)
  }

  const handleSuppliersChange = (
    selectOption: SelectOption | null,
    action: SelectionAction,
    selectedOptions?: SelectOption[]
  ) => {
    if (selectedOptions !== undefined) {
      setCurrentIngredientSuppliers(
        selectedOptions.map((o) => ({ name: o.label, id: o.value as string }))
      )
    }

    if (currentSimpleIngredient && selectOption) {
      if (action == 'select') {
        void dispatch(
          addSimpleIngredientToSupplier({
            companyId: currentCompany.id,
            supplierId: selectOption.value as string,
            simpleIngredientId: currentSimpleIngredient.id
          })
        )
          .unwrap()
          .catch(() => {
            showError(
              `Failed to add supplier, ${selectOption.label}, to ingredient.`
            )
          })
      } else {
        void dispatch(
          deleteSimpleIngredientFromSupplier({
            companyId: currentCompany.id,
            supplierId: selectOption.value as string,
            simpleIngredientId: currentSimpleIngredient.id
          })
        )
          .unwrap()
          .catch(() => {
            showError(
              `Failed to remove supplier, ${selectOption.label}, from ingredient.`
            )
          })
      }
    }
  }

  const handleTagsChange = (
    selectOption: SelectOption | null,
    action: SelectionAction,
    selectedOptions?: SelectOption[]
  ) => {
    if (selectedOptions !== undefined) {
      setCurrentTags(
        selectedOptions.map((o) => ({ name: o.label, id: o.value as string }))
      )
    }

    if (currentSimpleIngredient && selectOption) {
      if (action == 'select') {
        void dispatch(
          addSimpleIngredientTag({
            companyId: currentCompany.id,
            simpleIngredientId: currentSimpleIngredient.id,
            name: selectOption.label
          })
        )
          .unwrap()
          .then((it) => {
            setCurrentTags([...tags, it.tag])
          })
          .catch(() => {
            showError(
              `Failed to add tag, ${selectOption.label}, to ingredient.`
            )
            setCurrentTags(currentSimpleIngredient.tags)
          })
      } else {
        void dispatch(
          deleteSimpleIngredientTag({
            companyId: currentCompany.id,
            simpleIngredientId: currentSimpleIngredient.id,
            tagId: selectOption.value as string
          })
        )
          .unwrap()
          .catch(() => {
            showError(
              `Failed to remove tag, ${selectOption.label}, from ingredient.`
            )
            setCurrentTags(currentSimpleIngredient.tags)
          })
      }
    }
  }

  const handleAllergensChange = (
    selectOption: SelectOption | null,
    action: SelectionAction,
    selectedOptions?: SelectOption[]
  ) => {
    if (selectedOptions !== undefined) {
      setCurrentAllergens(
        selectedOptions.map((o) => ({ name: o.label, type: o.value as string }))
      )
    }

    if (currentSimpleIngredient && selectOption) {
      if (action == 'select') {
        void dispatch(
          addSimpleIngredientAllergen({
            companyId: currentCompany.id,
            simpleIngredientId: currentSimpleIngredient.id,
            allergenType: selectOption.value as string
          })
        )
          .unwrap()
          .catch(() => {
            showError(
              `Failed to add allergen ${selectOption.label} to ingredient.`
            )
            setCurrentAllergens(
              currentSimpleIngredientAllergens.map((a) => a.allergen)
            )
          })
      } else {
        void dispatch(
          deleteSimpleIngredientAllergen({
            companyId: currentCompany.id,
            simpleIngredientId: currentSimpleIngredient.id,
            allergenType: selectOption.value as string
          })
        )
          .unwrap()
          .catch(() => {
            showError(
              `Failed to remove allergen ${selectOption.label} from ingredient.`
            )
            setCurrentAllergens(
              currentSimpleIngredientAllergens.map((a) => a.allergen)
            )
          })
      }
    }
  }

  const handleDelete = () => {
    if (currentSimpleIngredient) {
      showConfirmationModal({
        title: 'Delete Ingredient',
        message: (
          <>
            Are you sure you want to delete{' '}
            <b>{currentSimpleIngredient.name}</b>?
          </>
        ),
        danger: true,
        yesText: 'Delete',
        noText: 'Cancel',
        onYesClicked: () => {
          ingredientsAnalytics.deletedIngredient(currentSimpleIngredient.id)
          void dispatch(
            deleteSimpleIngredient({
              companyId: currentSimpleIngredient.company.id,
              simpleIngredientId: currentSimpleIngredient.id
            })
          )
            .unwrap()
            .then(() => {
              onIngredientUpdate(undefined, true)
              showSuccess(`${currentSimpleIngredient.name} has been deleted.`)
            })
            .catch(() =>
              showError(`Failed to delete ${currentSimpleIngredient.name}.`)
            )
        }
      })
    }
  }

  const getCurrentSuppliers = (
    currentSimpleIngredient?: DetailedSimpleIngredient
  ) => {
    if (currentSimpleIngredient) {
      void dispatch(
        getSimpleIngredientSuppliers({
          companyId: currentCompany.id,
          simpleIngredientId: currentSimpleIngredient.id
        })
      )
        .unwrap()
        .then((payload) => {
          setCurrentIngredientSuppliers(payload.map((s) => s.supplier))
        })
    } else {
      setCurrentIngredientSuppliers([])
    }
  }

  const getCurrentAllergens = (
    currentSimpleIngredient?: DetailedSimpleIngredient
  ) => {
    if (currentSimpleIngredient) {
      void dispatch(
        getSimpleIngredientAllergens({
          companyId: currentCompany.id,
          simpleIngredientId: currentSimpleIngredient.id
        })
      )
        .unwrap()
        .then((payload) => {
          setCurrentAllergens(payload.map((a) => a.allergen))
        })
    }
  }

  const fetchTags = () => {
    // get company tags
    void dispatch(
      getCompanyTags({
        companyId: currentCompany.id
      })
    )
      .unwrap()
      .catch(() => {
        showError('Failed to load company tags')
      })
  }

  const fetchSuppliers = () => {
    // get suppliers
    void dispatch(
      getSuppliers({ companyId: currentCompany.id, withPublicSuppliers: false })
    )
      .unwrap()
      .catch(() => {
        showError('Failed to load suppliers.')
      })
  }

  React.useEffect(() => {
    if (ingredient?.id) {
      ingredientsAnalytics.viewedIngredient(ingredient.id)
    }
  }, [ingredient?.id])

  React.useEffect(() => {
    setValid(false)
    setHasChanges(false)
    setCurrentSimpleIngredient(ingredient)
  }, [ingredient])

  React.useEffect(() => {
    if (currentSimpleIngredient && currentSimpleIngredient.id) {
      setCurrentTags(
        currentSimpleIngredient ? currentSimpleIngredient.tags : []
      )
      getCurrentSuppliers(currentSimpleIngredient)
      getCurrentAllergens(currentSimpleIngredient)
    }
  }, [currentSimpleIngredient?.id])

  const handleClearDensityOverride = () => {
    if (currentSimpleIngredient) {
      void dispatch(
        deleteSimpleIngredientDensityOverride({
          companyId: currentCompany.id,
          simpleIngredientId: currentSimpleIngredient.id
        })
      ).then(
        () =>
          void dispatch(
            getSimpleIngredient({
              companyId: currentCompany.id,
              simpleIngredientId: currentSimpleIngredient.id
            })
          )
            .unwrap()
            .then((res) => {
              dispatch(setSelectedSimpleIngredient(res))
            })
      )
    }
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <ViewerContainer
        data-show={show}
        onClick={(e) => e.stopPropagation()}
        ref={ref}
      >
        <ViewerRow sx={{ '&&': { gridTemplateColumns: 'auto auto' } }}>
          <BoxRow></BoxRow>

          <BoxRow gap="8px" justifyContent="flex-end">
            {!currentSimpleIngredient?.id && (
              <Button
                size="small"
                color="secondary"
                type="submit"
                disabled={!valid || !hasChanges}
              >
                Create Ingredient
              </Button>
            )}

            {!!currentSimpleIngredient?.id && (
              <>
                {!readOnly && (
                  <SavingStatusIndicator
                    status={saving ? SavingStatus.SAVING : SavingStatus.SAVED}
                  />
                )}

                <Button
                  size="small"
                  color="secondary"
                  onClick={onSelectedSimpleIngredientDuplicate}
                  disabled={!currentSimpleIngredient?.id}
                >
                  Duplicate
                </Button>

                <Button
                  size="small"
                  color="danger"
                  onClick={handleDelete}
                  disabled={!currentSimpleIngredient?.id || readOnly}
                >
                  Delete
                </Button>
              </>
            )}
          </BoxRow>
        </ViewerRow>

        <ViewerContent>
          <InputFieldLabel
            label="Name"
            name="name"
            initialValue={currentSimpleIngredient?.name}
            placeholder="Ingredient Name"
            scale="large"
            onChange={handleFormChange}
            debounceTime={currentSimpleIngredient?.id ? 1000 : undefined}
            disabled={readOnly}
          />

          <InputFieldLabel
            label="Ingredient Statement Name"
            name="statement"
            multiline
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault()
              }
            }}
            rows={3}
            initialValue={currentSimpleIngredient?.ingredientStatement}
            onChange={handleFormChange}
            debounceTime={currentSimpleIngredient?.id ? 1000 : undefined}
            disabled={readOnly}
          />

          <SelectOptionsLabel
            label="Status"
            name="verified"
            options={[
              { value: 1, label: 'Approved' },
              { value: 0, label: 'Not Approved' }
            ]}
            initialValue={currentSimpleIngredient?.verified ? 1 : 0}
            onChange={handleSelectChange}
            disabled={readOnly}
          />

          <ViewerRow sx={{ '&&': { alignItems: 'start' } }}>
            <InputFieldLabel
              label="Friendly ID"
              name="friendlyId"
              initialValue={currentSimpleIngredient?.friendlyId}
              onChange={handleFormChange}
              debounceTime={currentSimpleIngredient?.id ? 1000 : undefined}
              disabled={readOnly}
            />

            <InputFieldLabel
              label="Manufacturer"
              name="manufacturer"
              initialValue={currentSimpleIngredient?.manufacturer}
              onChange={handleFormChange}
              debounceTime={currentSimpleIngredient?.id ? 1000 : undefined}
              disabled={readOnly}
            />

            {!!currentSimpleIngredient?.id && (
              <>
                <AutoCompleteLabel
                  label="Suppliers"
                  options={ingredientSupplierOptions}
                  initialValue={currentIngredientSuppliers.map((s) => {
                    return { value: s.id, label: s.name }
                  })}
                  filterOptions={(options) => options}
                  onChange={handleSuppliersChange}
                  onFocus={fetchSuppliers}
                  size="small"
                  disableClearable
                  disableCloseOnSelect
                  disabled={readOnly}
                />

                <AutoCompleteLabel
                  label="Allergens"
                  options={allergenOptions}
                  initialValue={currentAllergens.map((a) => ({
                    label: a.name,
                    value: a.type
                  }))}
                  filterOptions={(options) => options} // TODO: This is due to AutcompleteLabel2 constraint.
                  onChange={handleAllergensChange}
                  size="small"
                  disableClearable
                  disableCloseOnSelect
                  disabled={readOnly}
                />

                <AutoCompleteLabel
                  label="Tags"
                  options={ingredientTagOptions}
                  initialValue={tags.map((t) => ({
                    label: t.name,
                    value: t.id
                  }))}
                  filterOptions={(options) => options}
                  onChange={handleTagsChange}
                  onFocus={fetchTags}
                  freeSolo={true}
                  size="small"
                  disableClearable
                  disableCloseOnSelect
                  disabled={readOnly}
                />

                <SelectOptionsLabel
                  label="Considered Added Sugar?"
                  name="isSugar"
                  options={[
                    { value: 1, label: 'Yes' },
                    { value: 0, label: 'No' }
                  ]}
                  initialValue={currentSimpleIngredient?.isSugar ? 1 : 0}
                  onChange={handleSelectChange}
                  disabled={readOnly}
                />
              </>
            )}
          </ViewerRow>
          {selectedSimpleIngredientId && (
            <IngredientCost
              simpleIngredientId={selectedSimpleIngredientId}
              disabled={readOnly}
            />
          )}

          <InputFieldLabel
            label="Description"
            name="description"
            initialValue={currentSimpleIngredient?.description}
            onChange={handleFormChange}
            debounceTime={currentSimpleIngredient?.id ? 1000 : undefined}
            multiline
            rows={5}
            disabled={readOnly}
          />

          {!!currentSimpleIngredient?.id && (
            <IngredientViewerPanel
              simpleIngredient={currentSimpleIngredient}
              readOnly={readOnly}
            />
          )}
        </ViewerContent>
      </ViewerContainer>
    </form>
  )
}
