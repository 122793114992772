import { Option } from 'components/common'
import { LoadingStateContext } from 'components/LoadingUI/LoadingUIContext'
import { ModalContext } from 'components/Modal/ModalContext'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { DownloadModalContent } from './DownloadModal'
import { getReport } from 'state/labels/nutritions/NutritionFactLabelsSlice'
import html2canvas from '@cedar-kr/html2canvas'
import { createDownloadFileOperation } from 'common/download-utils'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { FormulaNutritionFactLabelType } from 'models/FormulaLabel'

const constructReportFileName = (formulaName: string) => {
  const utcDate = new Date().toISOString().split('T')[0] + ' UTC'
  return `Report - ${formulaName} - ${utcDate}.pdf`
}

const constructReportDownloadTrackerMsg = (
  percentCompleted: number | undefined
) => {
  return `Downloading Report ${
    percentCompleted ? '- ' + percentCompleted + '%' : '...'
  }`
}

export const ReportDownloadModalContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const formulaName = useAppSelector((state) => state.formulator.formula.name)
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const formulaRegulationId = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel?.regulationId
  )
  const formulaNutritionFactsType = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel?.type
  )

  const { showError } = React.useContext(SnackbarContext)
  const { closeModal } = React.useContext(ModalContext)
  const { showLoading, hideLoading, updateLoadingMessage } =
    React.useContext(LoadingStateContext)

  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  enum OptionId {
    LINK_TO_ENTR = 'link-to-entr',
    NUTRITION_FACTS = 'nutrition-facts',
    INGREDIENT_STATEMENT = 'ingredient-statement',
    ALLERGEN_STATEMENT = 'allergen-statement',
    BUSINESS_ADDRESS = 'business-address',
    NOTES = 'notes',
    TARGETS = 'targets',
    NUTRITIONALS = 'nutritionals'
  }

  const [options, setOptions] = useState([
    { id: OptionId.LINK_TO_ENTR, label: 'Link to ENTR', value: true },
    { id: OptionId.NUTRITION_FACTS, label: 'Nutrition Facts', value: true },
    {
      id: OptionId.INGREDIENT_STATEMENT,
      label: 'Ingredient Statement',
      value: true
    },
    {
      id: OptionId.ALLERGEN_STATEMENT,
      label: 'Allergen Statement',
      value: true
    },
    { id: OptionId.BUSINESS_ADDRESS, label: 'Business Address', value: true },
    { id: OptionId.NOTES, label: 'Notes', value: true },
    { id: OptionId.TARGETS, label: 'Targets', value: true },
    {
      id: OptionId.NUTRITIONALS,
      label: 'Nutritionals',
      value: true
    }
  ])

  const handleChange = (updatedOption: Option<boolean>) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) => {
        if (option.id === updatedOption.id) {
          return {
            ...option,
            value: updatedOption.value
          }
        }
        return option
      })
      return updatedOptions
    })
  }

  const getNFPImage = async () => {
    const element = document.querySelector('#nutrition-facts')
    if (element !== null && element instanceof HTMLElement) {
      const canvas = await html2canvas(element, {
        scale: 10,
        backgroundColor: 'white'
      })
      return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob)
          } else {
            reject(new Error('Failed to convert canvas to blob.'))
          }
        })
      })
    } else {
      throw new Error('Could not find nutrition facts panel.')
    }
  }

  const handleDownload = (options: Option<boolean>[]) => {
    showLoading({
      blockUI: true,
      message: 'Generating Report. This might take a few seconds...'
    })
    const optionsMap = options.reduce((acc, option) => {
      acc[option.id] = option.value
      return acc
    }, {} as Record<string, boolean>)

    formulatorAnalytics.label.downloadedReport(
      formulaId,
      formulaNutritionFactsType || FormulaNutritionFactLabelType.VERTICAL,
      formulaRegulationId || ''
    )

    getNFPImage()
      .then((nfpFile) => {
        void dispatch(
          getReport({
            companyId: companyId,
            formulaId: formulaId,
            parameters: {
              includeAllergenStatements:
                optionsMap[OptionId.ALLERGEN_STATEMENT],
              includeBusinessAddresses: optionsMap[OptionId.BUSINESS_ADDRESS],
              includeFormulaNotes: optionsMap[OptionId.NOTES],
              includeIngredientStatements:
                optionsMap[OptionId.INGREDIENT_STATEMENT],
              includeLink: optionsMap[OptionId.LINK_TO_ENTR],
              includeNfp: optionsMap[OptionId.NUTRITION_FACTS],
              includeNutritionals: optionsMap[OptionId.NUTRITIONALS],
              includeTargets: optionsMap[OptionId.TARGETS]
            },
            formulaLink: window.location.href,
            nfpFile: nfpFile
          })
        )
          .unwrap()
          .then((url) => {
            closeModal()
            const reportDownloadOperation = createDownloadFileOperation({
              url: url,
              fileName: constructReportFileName(formulaName),
              fileType: 'application/pdf',
              onDownloadProgress: (progressEvent) => {
                updateLoadingMessage(
                  constructReportDownloadTrackerMsg(
                    progressEvent.percentCompleted
                  )
                )
              },
              onError: (error) =>
                showError('Could not generate custom report.'),
              onFinished: () => hideLoading()
            })

            reportDownloadOperation.execute()
          })
          .catch(() => {
            showError('Could not generate custom report.')
            hideLoading()
          })
      })
      .catch(() => {
        showError('Could not generate custom report.')
      })
  }

  const downloadDisabled = React.useMemo(() => {
    // Disable download if all the options are off.
    return options.every((o) => !o.value)
  }, [options])

  return (
    <DownloadModalContent
      title="Custom Report Download"
      options={options}
      onChange={handleChange}
      onDownloadClick={handleDownload}
      onCancelClick={closeModal}
      downloadDisabled={downloadDisabled}
    />
  )
}
