import { Box, Typography } from '@mui/material'
import { langDisplaySorter } from 'common/utils'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import {
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import React from 'react'

export interface IngredientStatementProps {
  statement: FormulaNutritionFactLabelLangSpecificFields
  suggestedStatement: FormulaNutritionFactLabelLangSpecificFields
  prefix: FormulaNutritionFactLabelLangSpecificFields
}

export const IngredientStatement: React.FC<IngredientStatementProps> = ({
  statement,
  suggestedStatement,
  prefix
}) => {
  return (
    <Box width="100%">
      {Object.keys(statement)
        .sort((a, b) => {
          return langDisplaySorter(
            a as FormulaNutritionFactLanguage,
            b as FormulaNutritionFactLanguage
          )
        })
        .map((language) => {
          const languageKey = language as FormulaNutritionFactLanguage
          if (!(statement[languageKey] || suggestedStatement[languageKey])) {
            return null
          }
          return (
            <MarkdownRenderer
              key={languageKey}
              markdownText={`${prefix[languageKey]}${
                statement[languageKey] || suggestedStatement[languageKey] || ''
              }`}
            />
          )
        })}
    </Box>
  )
}
