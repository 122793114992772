import {
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient,
  FormulaNutritionFactNutrientLabelDisplay
} from 'models/FormulaLabel'
import { ItemTableContent } from '../../components/ItemTableContent'
import { NutritionFactsRule } from '../../components/NutritionFactsRule'
import { NutritionLabelDualColumn } from './NutritionLabelDualColumn'

export const NutrientsTable: React.FC<{
  perServingTopNutrients: FormulaNutritionFactNutrientLabelDisplay[]
  perContainerTopNutrients: FormulaNutritionFactNutrient[]
  perServingBottomNutrients: FormulaNutritionFactNutrientLabelDisplay[]
  perContainerBottomNutrients: FormulaNutritionFactNutrient[]
  showDvPercentageStar?: boolean
  perServingCalories?: string
  perContainerCalories?: string
}> = ({
  perServingTopNutrients = [],
  perContainerTopNutrients = [],
  perServingBottomNutrients = [],
  perContainerBottomNutrients = [],
  showDvPercentageStar = true,
  perServingCalories,
  perContainerCalories
}) => (
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    <NutritionLabelDualColumn
      header="Calories"
      nutrientNameColumns={true}
      topNutrientsContent={
        <ItemTableContent
          key={`topNutrients-name-${0}`}
          tableItemRowsProps={perServingTopNutrients.map((fnfn) => {
            return {
              nutrientType: fnfn.nutrient.type,
              itemRowValues: {
                name:
                  fnfn.displayNameOptions[
                    FormulaNutritionFactLanguage.ENGLISH
                  ] || ''
              }
            }
          })}
        />
      }
      bottomNutrientsContent={
        <ItemTableContent
          key={`bottomNutrients-name-${0}`}
          tableItemRowsProps={perServingBottomNutrients.map((fnfn) => {
            return {
              nutrientType: fnfn.nutrient.type,
              itemRowValues: {
                name:
                  fnfn.displayNameOptions[
                    FormulaNutritionFactLanguage.ENGLISH
                  ] || ''
              }
            }
          })}
        />
      }
    />
    <NutritionFactsRule orientation="vertical" type="hairline" margin={2} />
    <NutritionLabelDualColumn
      preHeader="Per serving"
      header={perServingCalories || ''}
      rightHeader={true}
      dvHeader={'% DV' + (showDvPercentageStar ? '*' : '')}
      topNutrientsContent={
        <ItemTableContent
          key={`topNutrients-serving-${0}`}
          tableItemRowsProps={perServingTopNutrients.map((fnfn) => {
            return {
              nutrientType: fnfn.nutrient.type,
              numberOnly: true,
              itemRowValues: {
                name: '',
                amount: fnfn.displayAmountOptions[0]?.amount,
                unit: fnfn.nutrient.unit,
                dvPercentage: fnfn.displayDvOptions[0]?.amount
              }
            }
          })}
        />
      }
      bottomNutrientsContent={
        <ItemTableContent
          key={`bottomNutrients-serving-${0}`}
          tableItemRowsProps={perServingBottomNutrients.map((fnfn) => {
            return {
              nutrientType: fnfn.nutrient.type,
              numberOnly: true,
              itemRowValues: {
                name: '',
                amount: fnfn.displayAmountOptions[0]?.amount,
                unit: fnfn.nutrient.unit,
                dvPercentage: fnfn.displayDvOptions[0]?.amount
              }
            }
          })}
        />
      }
    />
    <NutritionFactsRule orientation="vertical" type="hairline" margin={2} />
    <NutritionLabelDualColumn
      preHeader="Per container"
      header={perContainerCalories || ''}
      rightHeader={true}
      dvHeader={'% DV' + (showDvPercentageStar ? '*' : '')}
      topNutrientsContent={
        <ItemTableContent
          key={`topNutrients-container-${0}`}
          tableItemRowsProps={perContainerTopNutrients.map((fnfn) => {
            return {
              nutrientType: fnfn.nutrient.type,
              numberOnly: true,
              itemRowValues: {
                name: '',
                amount: fnfn.displayAmountOptions[0]?.amount,
                unit: fnfn.nutrient.unit,
                dvPercentage: fnfn.displayDvOptions[0]?.amount
              }
            }
          })}
        />
      }
      bottomNutrientsContent={
        <ItemTableContent
          key={`bottomNutrients-container-${0}`}
          tableItemRowsProps={perContainerBottomNutrients.map((fnfn) => {
            return {
              nutrientType: fnfn.nutrient.type,
              numberOnly: true,
              itemRowValues: {
                name: '',
                amount: fnfn.displayAmountOptions[0]?.amount,
                unit: fnfn.nutrient.unit,
                dvPercentage: fnfn.displayDvOptions[0]?.amount
              }
            }
          })}
        />
      }
    />
  </div>
)
