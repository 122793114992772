import React from 'react'
import { NutritionFactsProps } from '../NutritionLabel'
import { Container } from '../components/NutritionLabelStyles'
import { NutritionLabelText } from '../components/NutritionLabelText'
import {
  NutritionLabelLinearBox,
  NutritionLabelLinearContainer
} from './NutritionLabelLinearStyles'
import { ItemLinearContent } from './components/ItemLinearContent'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'

export const NutritionLabelLinear: React.FC<NutritionFactsProps> = ({
  servingsPerContainer,
  servingSize,
  servingWeight,
  topNutrients,
  bottomNutrients,
  calories
}) => {
  return (
    <Container>
      <NutritionLabelLinearBox>
        <NutritionLabelLinearContainer>
          <NutritionLabelText bold points={12} wrapText>
            Nutrition Facts{' '}
          </NutritionLabelText>
          <NutritionLabelText points={9} wrapText>
            Servings: {servingsPerContainer},{' '}
          </NutritionLabelText>
          {Object.keys(servingSize).map((language) => (
            <NutritionLabelText points={9} wrapText key={`ss-${language}`} bold>
              Serv. size:{' '}
              {servingSize[language as FormulaNutritionFactLanguage]} (
              {servingWeight}),{' '}
            </NutritionLabelText>
          ))}
          <NutritionLabelText points={8} wrapText>
            Amount per serving:{' '}
          </NutritionLabelText>
          <NutritionLabelText bold points={10} wrapText>
            Calories{' '}
          </NutritionLabelText>
          <NutritionLabelText bold points={15} wrapText>
            {calories}
          </NutritionLabelText>
          <NutritionLabelText bold points={10} wrapText>
            ,{' '}
          </NutritionLabelText>
          <ItemLinearContent
            itemsLinearProps={[...topNutrients, ...bottomNutrients].map(
              (fnfn) => {
                return {
                  nutrientType: fnfn.nutrient.type,
                  itemLinearValues: {
                    name:
                      fnfn.displayNameOptions[
                        FormulaNutritionFactLanguage.ENGLISH
                      ] || '',
                    amount: fnfn.displayAmountOptions[0]?.amount,
                    unit: fnfn.nutrient.unit,
                    dvPercentage: fnfn.displayDvOptions[0]?.amount
                  }
                }
              }
            )}
          />
        </NutritionLabelLinearContainer>
      </NutritionLabelLinearBox>
    </Container>
  )
}
