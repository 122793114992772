import { Box } from '@mui/material'
import { SideBarLayout } from 'components/SideBarLayout/SideBarLayout'
import { TopBarLayout } from 'components/TopBarLayout/TopBarLayout'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import { useParams } from 'react-router-dom'
import { resetState as resetCommentsState } from 'state/comments/CommentsSlice'
import {
  getFormula,
  getFormulaTotalCost,
  resetState as resetFormulatorState
} from 'state/formulator/FormulatorSlice'
import { resetState as resetFormulaAllergenState } from 'state/formulator/allergens/FormulaAllergensSlice'
import { resetState as resetHistoryState } from 'state/formulator/history/FormulaHistorySlice'
import { resetState as resetFormulatorIngredientsState } from 'state/formulator/ingredients/FormulatorIngredientsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { resetState as resetLabelState } from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { resetTargetsState } from 'state/targets'
import { FormulatorIngredientsContainer } from './components/FormulatorIngredients/FormulatorIngredientsContainer'
import { FormulatorProvider } from './components/FormulatorIngredients/FormulatorProvider'
import { FormulatorPanel } from './components/FormulatorPanel/FormulatorPanel'
import { FormulatorTopBarContainer } from './components/FormulatorTopBar/FormulatorTopBarContainer'

export const Formulator: React.FC = () => {
  const dispatch = useAppDispatch()
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)
  const { id } = useParams()
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaIdSelector: string = useAppSelector(
    (state) => state.formulator.formula.id
  )

  const resetState = () => {
    void dispatch(resetFormulaAllergenState())
    void dispatch(resetFormulatorIngredientsState())
    void dispatch(resetFormulatorState())
    void dispatch(resetLabelState())
    void dispatch(resetHistoryState())
    void dispatch(resetCommentsState())
    void dispatch(resetTargetsState())
  }

  React.useEffect(() => {
    return () => {
      resetState()
    }
  }, [])

  React.useEffect(() => {
    if (id && id !== formulaIdSelector) {
      formulatorAnalytics.viewedPage(id)
      // Reset the state before loading the new formula.
      void dispatch(getFormula({ companyId: currentCompany.id, formulaId: id }))
    }
  }, [id, formulaIdSelector, currentCompany])

  return (
    <Box>
      <FormulatorProvider>
        <TopBarLayout topBar={<FormulatorTopBarContainer />}>
          <SideBarLayout sidebar={<FormulatorPanel />} alwaysOpen={false}>
            <FormulatorIngredientsContainer />
          </SideBarLayout>
        </TopBarLayout>
      </FormulatorProvider>
    </Box>
  )
}
