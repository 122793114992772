import React from 'react'
import {
  NutritionLabelText,
  NutritionLabelTextProps
} from './NutritionLabelText'

export type NutritionLabelTextCanadianProps = Omit<
  NutritionLabelTextProps,
  'font'
>

export const NutritionLabelTextCanadian: React.FC<
  NutritionLabelTextCanadianProps
> = (props) => {
  return (
    <NutritionLabelText {...props} font="arial">
      {props.children}
    </NutritionLabelText>
  )
}
