import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'
import React from 'react'

export const DynamicColgroup = ({
  availableLanguagesInOverrides,
  containerRef,
  showPerContainerFields = false
}: {
  availableLanguagesInOverrides: FormulaNutritionFactLanguage[]
  containerRef: React.RefObject<HTMLDivElement>
  showPerContainerFields?: boolean
}) => {
  const [containerWidth, setContainerWidth] = React.useState<number>(0)

  React.useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width)
    }
  }, [containerRef])

  const columnWidths = React.useMemo(() => {
    // Calculate widths as if there's always one language column and no container column
    const totalColumns = 6 // 5 fixed columns + 1 language column
    const baseWidth = containerWidth / totalColumns

    return {
      width30: baseWidth * 1.8,
      width10: baseWidth * 0.6
    }
  }, [containerWidth])

  return (
    <>
      <colgroup>
        <col style={{ width: columnWidths.width30, minWidth: '150px' }} />
        <col style={{ width: columnWidths.width10, minWidth: '100px' }} />
        <col style={{ width: columnWidths.width10, minWidth: '100px' }} />
        {availableLanguagesInOverrides.map((lang) => (
          <col
            key={`lang-${lang}`}
            style={{ width: columnWidths.width30, minWidth: '150px' }}
          />
        ))}
        <col style={{ width: columnWidths.width10, minWidth: '150px' }} />
        <col style={{ width: columnWidths.width10, minWidth: '150px' }} />
        {showPerContainerFields && (
          <>
            <col style={{ width: columnWidths.width10, minWidth: '150px' }} />
            <col style={{ width: columnWidths.width10, minWidth: '150px' }} />
          </>
        )}
      </colgroup>
    </>
  )
}
