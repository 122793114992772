import React from 'react'
import {
  areNutrientRowPropsEqual,
  NutrientRow,
  NutrientRowProps
} from './NutrientRow'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'
import { Skeleton, TableCell, TableRow } from '@mui/material'

export interface NutrientRowGroupProps {
  nutrientRows: NutrientRowProps[]
  availableLanguagesInOverrides?: FormulaNutritionFactLanguage[]
  placeholderRows?: number
  colSpan?: number
}

const getPlaceholderRows = (rows: number, colSpan: number) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, idx) => (
        <TableRow key={`ng-placeholder-${idx}`}>
          <TableCell colSpan={colSpan}>
            <Skeleton variant={'rectangular'} height={'19px'} />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

const arePropsEqual = (
  prevProps: NutrientRowGroupProps,
  nextProps: NutrientRowGroupProps
): boolean => {
  return (
    prevProps.nutrientRows.length === nextProps.nutrientRows.length &&
    prevProps.placeholderRows === nextProps.placeholderRows &&
    prevProps.colSpan === nextProps.colSpan &&
    prevProps.availableLanguagesInOverrides ===
      nextProps.availableLanguagesInOverrides &&
    prevProps.nutrientRows.every((nr, idx) => {
      const nextNr = nextProps.nutrientRows[idx]
      return areNutrientRowPropsEqual(nr, nextNr)
    })
  )
}

export const NutrientRowGroup = React.memo(
  ({
    nutrientRows,
    availableLanguagesInOverrides = [FormulaNutritionFactLanguage.ENGLISH],
    placeholderRows = 5,
    colSpan = 6
  }: NutrientRowGroupProps) => {
    return (
      <React.Fragment>
        {nutrientRows.length > 0 &&
          nutrientRows.map((nr, index) => (
            <NutrientRow
              key={`nr-${index}`}
              nutrient={nr.nutrient}
              level={nr.level}
              overrides={nr.overrides}
              disabledName={nr.disabledName}
              availableLanguagesInOverrides={availableLanguagesInOverrides}
            />
          ))}
        {nutrientRows.length === 0 &&
          getPlaceholderRows(placeholderRows, colSpan)}
      </React.Fragment>
    )
  },
  arePropsEqual
)
