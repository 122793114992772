import { NutritionFactsRule } from 'components/NutritionLabel/components/NutritionFactsRule'
import { NutrientType } from 'models/Nutrient'
import React from 'react'
import { FatItemRow } from './FatItemRow'
import { ItemRow, ItemRowSettings, ItemRowValues } from './ItemRow'

export interface TableContentProps {
  tableItemRowsProps: TableItemRowProps[]
}

export interface TableItemRowProps {
  nutrientType: NutrientType
  itemRowValues: ItemRowValues
  numberOnly?: boolean
}

export const NUTRIENT_TYPE_ITEM_ROW_SETTINGS: Partial<
  Record<NutrientType, ItemRowSettings>
> = {
  [NutrientType.TOTAL_FAT]: {
    boldName: true
  },
  [NutrientType.SATURATED_FAT]: {
    level: 1
  },
  [NutrientType.TRANS_FATTY_ACID]: {
    level: 1
  },
  [NutrientType.POLY_FAT]: {
    level: 1
  },
  [NutrientType.OMEGA_6_FATTY_ACID]: {
    level: 2
  },
  [NutrientType.OMEGA_3_FATTY_ACID]: {
    level: 2
  },
  [NutrientType.MONO_FAT]: {
    level: 1
  },
  [NutrientType.CHOLESTEROL]: {
    boldName: true
  },
  [NutrientType.SODIUM]: {
    boldName: true
  },
  [NutrientType.CARBOHYDRATES]: {
    boldName: true
  },
  [NutrientType.DIETARY_FIBER]: {
    level: 1
  },
  [NutrientType.SOLUBLE_FIBER]: {
    level: 2
  },
  [NutrientType.INSOLUBLE_FIBER]: {
    level: 2
  },
  [NutrientType.TOTAL_SUGARS]: {
    level: 1
  },
  [NutrientType.SUGAR_ALCOHOL]: {
    level: 1
  },
  [NutrientType.STARCH]: {
    level: 1
  },
  [NutrientType.PROTEIN]: {
    boldName: true
  }
}

const getTableItemRowSettings = (
  nutrientType: NutrientType
): ItemRowSettings => {
  return NUTRIENT_TYPE_ITEM_ROW_SETTINGS[nutrientType] || {}
}

export const ItemTableContent: React.FC<TableContentProps> = ({
  tableItemRowsProps = []
}) => {
  // Create the fat item row by extracting the saturated and trans fat.
  const fatItemRow = React.useMemo(() => {
    const saturated = tableItemRowsProps.find(
      (itemRowProps) => itemRowProps.nutrientType === NutrientType.SATURATED_FAT
    )
    const trans = tableItemRowsProps.find(
      (itemRowProps) =>
        itemRowProps.nutrientType === NutrientType.TRANS_FATTY_ACID
    )

    return saturated && trans ? (
      <FatItemRow
        saturated={saturated?.itemRowValues}
        trans={trans?.itemRowValues}
        level={1}
      />
    ) : (
      <></>
    )
  }, [tableItemRowsProps])

  return (
    <>
      {tableItemRowsProps.map((tableItemRowProps, index) => {
        const itemRowSettings = getTableItemRowSettings(
          tableItemRowProps.nutrientType
        )

        return (
          <React.Fragment key={`fragment-${tableItemRowProps.nutrientType}`}>
            {index > 0 &&
              (itemRowSettings.level === 0 ||
                itemRowSettings.level === undefined) && (
                <NutritionFactsRule
                  key={`separator-${tableItemRowProps.nutrientType}`}
                  type="hairline"
                  regulation="canadian"
                  margin={0.5}
                />
              )}

            {![
              NutrientType.SATURATED_FAT,
              NutrientType.TRANS_FATTY_ACID
            ].includes(tableItemRowProps.nutrientType) && (
              <ItemRow
                key={`item-row-${tableItemRowProps.nutrientType}`}
                {...itemRowSettings}
                level={tableItemRowProps.numberOnly ? 0 : itemRowSettings.level}
                {...tableItemRowProps.itemRowValues}
              />
            )}

            {NutrientType.TRANS_FATTY_ACID === tableItemRowProps.nutrientType &&
              fatItemRow}
          </React.Fragment>
        )
      })}
    </>
  )
}
