import { QueriesProvider } from 'components/EntrTable/Filter/QueriesContext'
import { Ingredients } from './Ingredients'
import { IngredientsTableContainer } from './components/IngredientsTable/IngredientsTableContainer'
import { IngredientsSearchFiltersContainer } from './components/IngredientsSearchFilters/IngredientsSearchFiltersContainer'
import { IngredientsViewerContainer } from './components/IngredientViewer/IngredientViewerContainer'
import {
  PageViewerContext,
  PageViewerProvider
} from './components/PageViewerContext'
import React from 'react'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'

export const IngredientsContainer: React.FC = () => {
  return (
    <PageViewerProvider queryKey="ingredientId">
      <_IngredientsContainer />
    </PageViewerProvider>
  )
}

const _IngredientsContainer: React.FC = () => {
  const { sendShowRequest, sendHideRequest } =
    React.useContext(PageViewerContext)
  const { ingredientsAnalytics } = React.useContext(AnalyticsContext)

  React.useEffect(() => {
    ingredientsAnalytics.viewedPage()
  }, [])

  const handleClickAway = React.useCallback(() => {
    sendHideRequest()
  }, [sendHideRequest])

  const handleNewIngredientClick = React.useCallback(() => {
    sendShowRequest()
  }, [sendShowRequest])

  return (
    <QueriesProvider>
      <Ingredients
        ingredientViewer={<IngredientsViewerContainer />}
        onClickAway={handleClickAway}
        onNewIngredientClick={handleNewIngredientClick}
        filterAndSearch={<IngredientsSearchFiltersContainer />}
        table={<IngredientsTableContainer />}
      />
    </QueriesProvider>
  )
}
