import { FormulaNutritionFactLanguage, FormulaNutritionFactNutrient, FormulaNutritionFactNutrientLabelDisplay } from 'models/FormulaLabel'
import { ItemTableContent } from '../../components/ItemTableContent'
import { NutritionFactsRule } from '../../components/NutritionFactsRule'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import { PercentDailyValueContainer } from '../NutritionLabelVerticalStyles'

export const TopNutrientsTable: React.FC<{
  topNutrients: FormulaNutritionFactNutrientLabelDisplay[]
  showDvPercentageStar?: boolean
}> = ({ topNutrients = [], showDvPercentageStar = true }) => (
  <>
    <PercentDailyValueContainer>
      <NutritionLabelText bold points={8}>
        &nbsp;
      </NutritionLabelText>
      <NutritionLabelText bold points={6}>
        % Daily Value{showDvPercentageStar ? '*' : ''}
      </NutritionLabelText>
    </PercentDailyValueContainer>
    <NutritionFactsRule type="hairline" margin={2} />
    <ItemTableContent
      key={`topNutrients-${0}`}
      tableItemRowsProps={topNutrients.map((fnfn) => {
        return {
          nutrientType: fnfn.nutrient.type,
          itemRowValues: {
            name: fnfn.displayNameOptions[FormulaNutritionFactLanguage.ENGLISH] || '',
            amount: fnfn.displayAmountOptions[0]?.amount,
            unit: fnfn.nutrient.unit,
            dvPercentage: fnfn.displayDvOptions[0]?.amount
          }
        }
      })}
    />
  </>
)
