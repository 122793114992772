import { pluralize } from 'common/utils'
import { FormulaNutritionFactLabelAgeGroup } from 'models/FormulaLabel'
import React from 'react'
import { NutritionFactsProps } from '../NutritionLabel'
import { NutritionFactsRule } from '../components/NutritionFactsRule'
import {
  Container,
  FlexContainerColumnSpaced,
  FlexContainerRowSpaced,
  ServingContainer
} from '../components/NutritionLabelStyles'
import { NutritionLabelText } from '../components/NutritionLabelText'
import {
  AmountPerServingContainer,
  FootNoteContainer,
  NutritionFactsHeaderContainer,
  NutritionLabelVerticalBox
} from './NutritionLabelVerticalStyles'
import { BottomNutrientsTable } from './components/BottomNutrientsTable'
import { TopNutrientsTable } from './components/TopNutrientsTable'

export const NutritionLabelVertical: React.FC<NutritionFactsProps> = ({
  servingsPerContainer,
  servingSize,
  servingWeight,
  topNutrients,
  bottomNutrients,
  calories,
  ageGroup
}) => {
  return (
    <Container>
      <NutritionLabelVerticalBox>
        <NutritionFactsHeaderContainer>
          <NutritionLabelText bold points={20}>
            Nutrition Facts
          </NutritionLabelText>
        </NutritionFactsHeaderContainer>

        <NutritionFactsRule type="hairline" margin={2} />

        <ServingContainer>
          <NutritionLabelText points={10}>
            {servingsPerContainer} {pluralize(servingsPerContainer, 'serving')}{' '}
            per container
          </NutritionLabelText>
          <FlexContainerRowSpaced>
            <NutritionLabelText bold points={10}>
              Serving size&nbsp;{' '}
              {/* Added empty space to keep one character between long serving size and Serving Size */}
            </NutritionLabelText>
            {Object.keys(servingSize).map((language) => (
              <NutritionLabelText key={language} bold points={10}>
                {servingSize[language as keyof typeof servingSize]} (
                {servingWeight})
              </NutritionLabelText>
            ))}
          </FlexContainerRowSpaced>
        </ServingContainer>

        <NutritionFactsRule type="thick" margin={2} />

        <AmountPerServingContainer>
          <FlexContainerColumnSpaced>
            <NutritionLabelText bold points={6}>
              Amount per serving
            </NutritionLabelText>
            <NutritionLabelText bold points={16}>
              Calories
            </NutritionLabelText>
          </FlexContainerColumnSpaced>
          <NutritionLabelText bold points={22}>
            {calories}
          </NutritionLabelText>
        </AmountPerServingContainer>

        <NutritionFactsRule type="normal" margin={2} />

        <TopNutrientsTable
          topNutrients={topNutrients}
          showDvPercentageStar={
            ageGroup !== FormulaNutritionFactLabelAgeGroup.INFANT
          }
        />
        <NutritionFactsRule type="thick" margin={2} />

        <BottomNutrientsTable bottomNutrients={bottomNutrients} />

        {ageGroup != FormulaNutritionFactLabelAgeGroup.INFANT && (
          <>
            <NutritionFactsRule type="normal" margin={2} />

            <FootNoteContainer>
              <NutritionLabelText points={6}>*&nbsp; </NutritionLabelText>
              <NutritionLabelText points={6} wrapText={true}>
                The % Daily Value (DV) tells you how much a nutrient in a
                serving of food contributes to a daily diet.{' '}
                {ageGroup ===
                FormulaNutritionFactLabelAgeGroup.CHILDREN_LESS_THAN_FOUR
                  ? '1,000'
                  : '2,000'}{' '}
                calories a day is used for general nutrition advice.
              </NutritionLabelText>
            </FootNoteContainer>
          </>
        )}
      </NutritionLabelVerticalBox>
    </Container>
  )
}
