import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getFormulaHistory } from 'state/formulator/history/FormulaHistorySlice'
import {
  FormulaHistoryItem,
  FormulaHistoryItemSkeleton
} from './FormulaHistoryItem'
import { BoxColumnBasic } from 'components/common'
import { TabContent } from '../TabContent'
import { InfiniteScrollWrapper } from 'components/InfiniteScrollWrapper/InfiniteScrollWrapper'
import { useLoadNextPage } from 'common/hooks'
import { resetState } from 'state/formulator/history/FormulaHistorySlice'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'

export const FormulaHistoryItemsSkeleton: React.FC = () => {
  const numberOfItems = 6
  const skeletons = Array.from({ length: numberOfItems }, (_, index) => (
    <FormulaHistoryItemSkeleton key={index} />
  ))

  return <>{skeletons}</>
}

export const FormulaHistory: React.FC = () => {
  const dispatch = useAppDispatch()
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaHistory = useAppSelector((state) => state.formulaHistory.history)
  const { page, pages } = useAppSelector(
    (state) => state.formulaHistory.history.activity
  )
  const formulaHistoryLoading = useAppSelector(
    (state) => state.formulaHistory.loading
  )
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  const setCurrentPageCallback = (page: number) => {
    void dispatch(
      getFormulaHistory({
        companyId: currentCompany.id,
        formulaId: formulaId,
        page: page
      })
    )
  }

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page,
    pages,
    setCurrentPageCallback
  })

  React.useEffect(() => {
    if (formulaId && currentCompany.id) {
      formulatorAnalytics.history.viewedHistoryTab(formulaId)

      void dispatch(
        getFormulaHistory({
          companyId: currentCompany.id,
          formulaId: formulaId,
          page: page
        })
      )
    }
    return () => {
      dispatch(resetState())
    }
  }, [formulaId, currentCompany.id])

  const handleLoadMore = () => {
    if (allPagesLoaded) {
      return
    }
    nextPageCallback()
  }

  return (
    <TabContent>
      <BoxColumnBasic gap="2rem" height="100%">
        <InfiniteScrollWrapper
          onEndOfList={handleLoadMore}
          isLoading={formulaHistoryLoading}
          isHidden={formulaHistory.activity.items?.length === 0}
        >
          {formulaHistoryLoading &&
          formulaHistory.activity.items.length === 0 ? (
            <FormulaHistoryItemsSkeleton />
          ) : (
            formulaHistory.activity.items.map((activity) => {
              return (
                <FormulaHistoryItem
                  activity={activity}
                  key={`${activity.revisionId}${activity.createdAt}`}
                />
              )
            })
          )}
        </InfiniteScrollWrapper>
      </BoxColumnBasic>
    </TabContent>
  )
}
