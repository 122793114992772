import Analytics, { PageParams } from '@segment/analytics-node'
import { FormulaNutritionFactLabelType } from 'models/FormulaLabel'
import { v4 as uuidv4 } from 'uuid'

export class EntrAnalytics {
  private analytics?: Analytics
  private userId?: string
  private lastPageRecorded?: PageParams
  private sessionId?: string

  constructor(writeKey: string, enabled = true) {
    this.sessionId = uuidv4()
    if (enabled) {
      this.analytics = new Analytics({
        writeKey: writeKey
      })
    }
  }

  setUserId(userId: string): void {
    this.userId = userId
  }

  identify(name: string): void {
    if (this.analytics && this.userId) {
      this.analytics.identify({
        userId: this.userId,
        traits: {
          name: name
        }
      })
    }
  }

  private track(name: string, properties: object = {}): void {
    if (this.analytics && this.userId) {
      properties = {
        ...properties,
        session_id: this.sessionId
      }

      this.analytics.track({
        userId: this.userId,
        event: name,
        properties: properties,
        timestamp: new Date().toString()
      })
    }
  }

  private hasPageBeenRecorded(currentPage: PageParams): boolean {
    if (!this.lastPageRecorded) {
      return false
    }
    return (
      this.lastPageRecorded.name === currentPage.name &&
      JSON.stringify(this.lastPageRecorded.properties) ===
        JSON.stringify(currentPage.properties)
    )
  }

  private page(name: string, properties: object = {}): void {
    // TODO: Update logic once issue with unmount/mount of components is resolved.
    if (this.analytics && this.userId) {
      properties = {
        ...properties,
        session_id: this.sessionId
      }
      const newPage = {
        userId: this.userId,
        name: name,
        properties: properties,
        timestamp: new Date().toString()
      }
      if (!this.hasPageBeenRecorded(newPage)) {
        this.lastPageRecorded = newPage
        this.analytics.page(newPage)
      }
    }
  }

  formulasAnalytics = {
    viewedPage: () => this.page('Formulas'),
    createdNewFormula: () => this.track('Formula created'),
    duplicatedFormula: (formulaId: string) =>
      this.track('Formula duplicated', { formula_id: formulaId }),
    deletedFormula: (formulaId: string) =>
      this.track('Formula deleted', { formula_id: formulaId }),
    archivedFormula: (formulaId: string, archived: boolean) =>
      this.track('Formula archived/unarchived', {
        formula_id: formulaId,
        archived: archived
      }),
    modifiedFormulaName: (formulaId: string) =>
      this.track('Name modified', { formula_id: formulaId }),
    modifiedFormulaFriendlyId: (formulaId: string) =>
      this.track('Friendly ID modified', { formula_id: formulaId }),
    modifiedFormulaStatus: (formulaId: string) =>
      this.track('Status modified', { formula_id: formulaId })
  }

  ingredientsAnalytics = {
    viewedPage: () => this.page('Ingredients'),
    createdNewIngredient: () => this.track('Ingredient created'),
    viewedIngredient: (ingredientId: string) =>
      this.track('Ingredient viewed', { ingredient_id: ingredientId }),
    deletedIngredient: (ingredientId: string) =>
      this.track('Ingredient deleted', { ingredient_id: ingredientId }),
    duplicatedIngredient: (ingredientId: string) =>
      this.track('Ingredient duplicated', { ingredient_id: ingredientId }),
    uploadedDocument: (ingredientId: string) =>
      this.track('Document uploaded', { ingredient_id: ingredientId }),
    previewedDocument: (ingredientId: string) =>
      this.track('Document previewed', { ingredient_id: ingredientId }),
    deletedDocument: (ingredientId: string) =>
      this.track('Document deleted', { ingredient_id: ingredientId }),
    downloadedDocument: (ingredientId: string) =>
      this.track('Document downloaded', { ingredient_id: ingredientId })
  }

  formulatorAnalytics = {
    viewedPage: (formulaId: string) =>
      this.page('Formulator', { formula_id: formulaId }),
    notes: {
      modifiedNotes: (formulaId: string) =>
        this.track('Notes modified', { formula_id: formulaId }),
      uploadedDocument: (formulaId: string) =>
        this.track('Document uploaded', { formula_id: formulaId }),
      previewedDocument: (formulaId: string) =>
        this.track('Document previewed', { formula_id: formulaId }),
      deletedDocument: (formulaId: string) =>
        this.track('Document deleted', { formula_id: formulaId }),
      downloadedDocument: (formulaId: string) =>
        this.track('Document downloaded', { formula_id: formulaId })
    },
    ingredients: {
      advancedSearchedIngredient: (formulaId: string) =>
        this.track('Ingredient advanced searched', { formula_id: formulaId }),
      searchedIngredient: (formulaId: string) =>
        this.track('Ingredient searched', { formula_id: formulaId }),
      updatedWastePercentage: (formulaId: string) =>
        this.track('Waste percentage updated', { formula_id: formulaId }),
      modifiedIngredientSupplier: (formulaId: string) =>
        this.track('Ingredient supplier modified', { formula_id: formulaId }),
      addedIngredientToFormula: (formulaId: string) =>
        this.track('Ingredient added', { formula_id: formulaId }),
      removedIngredientFromFormula: (formulaId: string) =>
        this.track('Ingredient removed', { formula_id: formulaId }),
      modifiedIngredientPercentage: (formulaId: string) =>
        this.track('Ingredient percentage modified', { formula_id: formulaId }),
      modifiedIngredientWeight: (formulaId: string) =>
        this.track('Ingredient weight modified', { formula_id: formulaId }),
      modifiedIngredientMeasurement: (formulaId: string) =>
        this.track('Ingredient measurement modified', {
          formula_id: formulaId
        }),
      scaledIngredients: (formulaId: string) =>
        this.track('Ingredients scaled', { formula_id: formulaId }),
      addedYieldAdjustment: (formulaId: string) =>
        this.track('Yield adjustment added', { formula_id: formulaId }),
      removedYieldAdjustment: (formulaId: string) =>
        this.track('Yield adjustment removed', { formula_id: formulaId }),
      modifiedYieldAdjustment: (formulaId: string) =>
        this.track('Yield adjustment modified', { formula_id: formulaId })
    },
    targets: {
      viewedTargetsTab: (formulaId: string) =>
        this.page('Formulator', { formula_id: formulaId, tab: 'Targets' }),
      updatedReferenceFood: (formulaId: string) =>
        this.track('Reference food updated', { formula_id: formulaId }),
      updatedRACC: (formulaId: string) =>
        this.track('RACC updated', { formula_id: formulaId }),
      updatedMealOrMainDish: (formulaId: string) =>
        this.track('Meal or main dish updated', { formula_id: formulaId }),
      selectedTarget: (formulaId: string) =>
        this.track('Target selected', { formula_id: formulaId }),
      deselectedTarget: (formulaId: string) =>
        this.track('Target deselected', { formula_id: formulaId })
    },
    nutrients: {
      viewedFormulatorNutrientsTab: (formulaId: string) =>
        this.page('Formulator', { formula_id: formulaId, tab: 'Nutrients' }),
      hoveredOverNutrient: (formulaId: string) =>
        this.track('Nutrient hovered', { formula_id: formulaId })
    },
    label: {
      viewedLabelTab: (formulaId: string) =>
        this.page('Formulator', { formula_id: formulaId, tab: 'Label' }),
      modifiedIngredientStatement: (formulaId: string) =>
        this.track('Ingredient statement modified', { formula_id: formulaId }),
      modifiedServingWeight: (formulaId: string) =>
        this.track('Serving weight modified', { formula_id: formulaId }),
      downloadedReport: (
        formulaId: string,
        labelType: FormulaNutritionFactLabelType,
        regulationId: string
      ) =>
        this.track('Formula Report downloaded', {
          formula_id: formulaId,
          label_type: labelType,
          regulation_id: regulationId
        }),
      downloadedLabelImage: (
        formulaId: string,
        labelType: FormulaNutritionFactLabelType,
        regulationId: string
      ) =>
        this.track('Formula Label downloaded', {
          formula_id: formulaId,
          label_type: labelType,
          regulation_id: regulationId
        })
    },
    comments: {
      viewedCommentsTab: (formulaId: string) =>
        this.page('Formulator', { formula_id: formulaId, tab: 'Comments' }),
      addedComment: (formulaId: string) =>
        this.track('Comment added', { formula_id: formulaId })
    },
    history: {
      viewedHistoryTab: (formulaId: string) =>
        this.page('Formulator', { formula_id: formulaId, tab: 'History' })
    }
  }

  generalAnalytics = {
    loggedOut: () => this.track('Logged out')
  }
}
