import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { Formula, FormulaStatus } from 'models/Formula'
import React from 'react'
import {
  updateFormulaFriendlyId,
  updateFormulaName,
  updateFormulaStatus
} from 'state/formulator/FormulatorSlice'
import { getFormulaHistory } from 'state/formulator/history/FormulaHistorySlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { StatusBar } from './StatusBar'
import { useParams } from 'react-router'

export const StatusBarContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const formulaFriendlyId = useAppSelector(
    (state) => state.formulator.formula.friendlyId
  )
  const formulaStatus = useAppSelector(
    (state) => state.formulator.formula.status
  )
  const formulaName = useAppSelector((state) => state.formulator.formula.name)
  const formulaArchived = useAppSelector(
    (state) => state.formulator.formula.isArchived
  )

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const { id } = useParams()

  const { formulasAnalytics } = React.useContext(AnalyticsContext)

  const handleStatusChange = React.useCallback(
    (newStatus: FormulaStatus) => {
      formulasAnalytics.modifiedFormulaStatus(formulaId)
      if (newStatus != formulaStatus && formulaId && currentCompany.id) {
        void dispatch(
          updateFormulaStatus({
            companyId: currentCompany.id,
            formulaId: formulaId,
            status: newStatus
          })
        ).then((action) => {
          if (updateFormulaStatus.fulfilled.match(action)) {
            void dispatch(
              getFormulaHistory({
                companyId: currentCompany.id,
                formulaId: formulaId
              })
            )
          }
        })
      }
    },
    [formulaId, currentCompany.id, formulaStatus]
  )

  const handleNameChange = React.useCallback(
    (newTitle: string) => {
      formulasAnalytics.modifiedFormulaName(formulaId)
      void dispatch(
        updateFormulaName({
          companyId: currentCompany.id,
          formulaId: formulaId,
          name: newTitle
        })
      ).then((action) => {
        if (
          updateFormulaName.fulfilled.match(action) &&
          newTitle !== formulaName
        ) {
          void dispatch(
            getFormulaHistory({
              companyId: currentCompany.id,
              formulaId: formulaId
            })
          )
        }
      })
    },
    [currentCompany.id, formulaId, formulaName]
  )

  const handleFriendlyIdChange = React.useCallback(
    (newFriendlyId: string) => {
      formulasAnalytics.modifiedFormulaFriendlyId(formulaId)
      void dispatch(
        updateFormulaFriendlyId({
          companyId: currentCompany.id,
          formulaId: formulaId,
          friendlyId: newFriendlyId
        })
      ).then((action) => {
        if (
          updateFormulaFriendlyId.fulfilled.match(action) &&
          formulaFriendlyId !== newFriendlyId
        ) {
          void dispatch(
            getFormulaHistory({
              companyId: currentCompany.id,
              formulaId: formulaId
            })
          )
        }
      })
    },
    [currentCompany.id, formulaId, formulaName]
  )

  const loading = React.useMemo(() => {
    return id !== formulaId
  }, [id, formulaId])

  return (
    <StatusBar
      loading={loading}
      title={{ value: formulaName, onChange: handleNameChange }}
      friendlyId={{
        value: formulaFriendlyId,
        onChange: handleFriendlyIdChange
      }}
      status={{ value: formulaStatus, onChange: handleStatusChange }}
      archived={formulaArchived}
    />
  )
}
