import FileDownload from 'js-file-download'
import { api } from '../config/AxiosConfig'

import { AxiosProgressEvent } from 'axios'
import { Comment } from 'models/Comment'
import { BasicDocument } from 'models/Document'
import {
  Formula,
  FormulaAllergens,
  FormulaCost,
  FormulaNutrient,
  FormulaRegulation,
  FormulasPaginated
} from 'models/Formula'
import { FormulaHistory } from 'models/FormulaHistory'
import { FormulaIngredient } from 'models/FormulaIngredient'
import {
  FormulaIngredientStatement,
  FormulaLabelStatementCaseType,
  FormulaNutritionFact,
  FormulaNutritionFactLabelHeader as FormulaNutritionFactsHeader
} from 'models/FormulaLabel'
import { FormulaTarget } from 'models/FormulaTargets'
import { Nutrient } from 'models/Nutrient'
import { FileApi } from '../document/DocumentApi'
import { toBasicDocuments } from '../document/DocumentApiMapper'
import { DocumentApiResponse } from '../document/DocumentApiResponse'
import { toNutrients } from '../nutrient/NutrientApiMapper'
import {
  fromFormulaLabelStatementCaseType,
  fromFormulaNutritionFactLanguage,
  toComment,
  toComments,
  toFormula,
  toFormulaAllergens,
  toFormulaCost,
  toFormulaIngredient,
  toFormulaIngredientStatement,
  toFormulaIngredients,
  toFormulaNutrients,
  toFormulaNutritionFact,
  toFormulaNutritionFactLabelHeader as toFormulaNutritionFactsHeader,
  toFormulaRegulation,
  toFormulaTarget,
  toFormulaTargets,
  toFormulasPaginated
} from './FormulaApiMapper'
import {
  AddFormulaAllergensApiData,
  AddFormulaCommentApiData,
  AddFormulaIngredientApiData,
  AddFormulaNutritionFactsOptionalNutrientsApiData,
  AddYieldAdjustmentApiData,
  CreateFormulaApiData,
  CreateFormulaTargetApiData,
  DuplicateFormulaApiData,
  GetFormulaAllergensApiParams,
  GetFormulaApiParams,
  GetFormulaCreatedByFilterApiParams,
  GetFormulaNutrientsApiParams,
  GetFormulaNutrientsCSVApiParams,
  GetFormulaNutritionFactsApiParams,
  GetFormulaReportApiData,
  GetFormulaReportApiParams,
  NutrientOverrideApiData,
  RemoveFormulaAllergensApiParams,
  RemoveFormulaNutritionFactsOptionalNutrientsApiParams,
  UpdateFormulaApiData,
  UpdateFormulaIngredientApiData,
  UpdateFormulaIngredientStatementApiData,
  UpdateFormulaWeightApiData,
  UpdateNutritionFactLabelApiData as UpdateNutritionFactsApiData,
  UpdateNutritionFactsApiParams,
  UpdateYieldAdjustmentApiData,
  addFormulaRegulationApiData
} from './FormulaApiRequest'
import {
  FormulaAllergensApiResponse,
  FormulaApiPaginatedResponse,
  FormulaApiResponse,
  FormulaCommentApiPaginatedResponse,
  FormulaCommentApiResponse,
  FormulaCostApiResponse,
  FormulaCreatedByFilterApiResponse,
  FormulaIngredientApiPaginatedResponse,
  FormulaIngredientApiResponse,
  FormulaIngredientStatementApiResponse,
  FormulaNutrientOverrideApiResponse,
  FormulaNutrientsApiResponse,
  FormulaNutritionFactApiResponse,
  FormulaNutritionFactLabelHeaderApiResponse as FormulaNutritionFactsHeaderApiResponse,
  FormulaNutritionFactsOptionalNutrientsApiResponse,
  FormulaRegulationApiResponse,
  FormulaTargetApiResponse,
  FormulaYieldAdjustmentApiResponse
} from './FormulaApiResponse'
import { toFormulaHistory } from './FormulaHistoryApiMapper'
import { GetFormulaHistoryApiParams } from './FormulaHistoryApiRequest'
import { FormulaHistoryApiResponse } from './FormulaHistoryApiResponse'

export interface UpdateIngredientAmountResponseBody {
  id: string
  supplierIngredientId: string
  amount: number
  wastePercentage: number
}

export const FormulaApi = {
  getFormulas: (
    companyId: string,
    params: GetFormulaApiParams
  ): Promise<FormulasPaginated> => {
    return api
      .get<FormulaApiPaginatedResponse>(`/companies/${companyId}/formulas`, {
        params,
        paramsSerializer: {
          indexes: null
        }
      })
      .then((res) => {
        return toFormulasPaginated(res.data)
      })
  },

  getFormula: (companyId: string, formulaId: string): Promise<Formula> => {
    return api
      .get<FormulaApiResponse>(`/companies/${companyId}/formulas/${formulaId}`)
      .then((res) => {
        return toFormula(res.data)
      })
  },

  getFormulaTotalCost: (
    companyId: string,
    formulaId: string
  ): Promise<FormulaCost> => {
    return api
      .get<FormulaCostApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/total_cost`
      )
      .then((res) => {
        return toFormulaCost(res.data)
      })
  },

  createFormula: (
    companyId: string,
    data: CreateFormulaApiData
  ): Promise<Formula> => {
    return api
      .post<FormulaApiResponse>(`/companies/${companyId}/formulas`, data)
      .then((res) => {
        return toFormula(res.data)
      })
  },

  addFormulaRegulation: (
    companyId: string,
    formulaId: string,
    regulationId: string,
    data: addFormulaRegulationApiData
  ): Promise<FormulaRegulation> => {
    return api
      .put<FormulaRegulationApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/regulation/${regulationId}`,
        data
      )
      .then((res) => {
        return toFormulaRegulation(res.data)
      })
  },

  updateFormula: (
    companyId: string,
    formulaId: string,
    data: UpdateFormulaApiData
  ): Promise<Formula> => {
    return api
      .put<FormulaApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}`,
        data
      )
      .then((res) => {
        return toFormula(res.data)
      })
  },

  deleteFormula: (companyId: string, formulaId: string) => {
    return api.delete<void>(`companies/${companyId}/formulas/${formulaId}`)
  },

  duplicateFormula: (
    companyId: string,
    formulaId: string,
    data: DuplicateFormulaApiData
  ): Promise<Formula> => {
    return api
      .post<FormulaApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/duplicate`,
        data
      )
      .then((res) => {
        return toFormula(res.data)
      })
  },

  getHistory: (
    companyId: string,
    formulaId: string,
    params: GetFormulaHistoryApiParams
  ): Promise<FormulaHistory> => {
    return api
      .get<FormulaHistoryApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/history`,
        {
          params
        }
      )
      .then((res) => {
        return toFormulaHistory(res.data)
      })
  },

  createFormulaTarget: (
    companyId: string,
    formulaId: string,
    data: CreateFormulaTargetApiData
  ): Promise<FormulaTarget> => {
    return api
      .post<FormulaTargetApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/target_definitions/`,
        data
      )
      .then((res) => {
        return toFormulaTarget(res.data)
      })
  },

  getFormulaTargets: (
    companyId: string,
    formulaId: string,
    withExecutionResults = false
  ): Promise<FormulaTarget[]> => {
    return api
      .get<FormulaTargetApiResponse[]>(
        `/companies/${companyId}/formulas/${formulaId}/target_definitions`,
        {
          params: {
            withExecutionResults
          }
        }
      )
      .then((res) => {
        return toFormulaTargets(res.data)
      })
  },

  deleteFormulaTarget: (
    companyId: string,
    formulaId: string,
    targetId: string
  ): Promise<{ formulaId: string; targetId: string }> => {
    return api
      .delete(
        `/companies/${companyId}/formulas/${formulaId}/target_definitions/${targetId}`
      )
      .then(() => {
        return {
          formulaId,
          targetId
        }
      })
  },

  getComments: (companyId: string, formulaId: string): Promise<Comment[]> => {
    return api
      .get<FormulaCommentApiPaginatedResponse>(
        `/companies/${companyId}/formulas/${formulaId}/comments`
      )
      .then((res) => {
        return toComments(res.data)
      })
  },

  addComment: (
    companyId: string,
    formulaId: string,
    data: AddFormulaCommentApiData
  ): Promise<Comment> => {
    return api
      .post<FormulaCommentApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/comments`,
        data
      )
      .then((res) => {
        return toComment(res.data)
      })
  },

  getIngredients: (
    companyId: string,
    formulaId: string
  ): Promise<FormulaIngredient[]> => {
    return api
      .get<FormulaIngredientApiPaginatedResponse>(
        `/companies/${companyId}/formulas/${formulaId}/ingredients`
      )
      .then((res) => {
        return toFormulaIngredients(res.data)
      })
  },

  addIngredient: (
    companyId: string,
    formulaId: string,
    data: AddFormulaIngredientApiData
  ): Promise<FormulaIngredient> => {
    return api
      .post<FormulaIngredientApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/ingredients`,
        data
      )
      .then((res) => {
        return toFormulaIngredient(res.data)
      })
  },

  updateIngredient: (
    companyId: string,
    formulaId: string,
    formulaIngredientId: string,
    data: UpdateFormulaIngredientApiData
  ): Promise<FormulaIngredient> => {
    return api
      .put<FormulaIngredientApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/ingredients/${formulaIngredientId}`,
        data
      )
      .then((res) => {
        return toFormulaIngredient(res.data)
      })
  },

  deleteIngredients: (
    companyId: string,
    formulaId: string,
    formulaIngredientIds: string[]
  ): Promise<string[]> => {
    return api
      .delete(
        `/companies/${companyId}/formulas/${formulaId}/formula_ingredients?${formulaIngredientIds
          .map((id) => `formula_supplier_ingredient_ids=${id}`)
          .join('&')}`
      )
      .then(() => {
        return formulaIngredientIds
      })
  },

  getIngredientStatement: (
    companyId: string,
    formulaId: string,
    withSuggestions = false,
    regulationId?: string,
    statementCase?: FormulaLabelStatementCaseType,
    subIngredientStatementCase?: FormulaLabelStatementCaseType,
  ): Promise<FormulaIngredientStatement> => {
    const params = {
      withSuggestions: String(withSuggestions),
      regulationId: regulationId,
      statementCase: statementCase ? fromFormulaLabelStatementCaseType(statementCase) : undefined,
      subIngredientStatementCase: subIngredientStatementCase ? fromFormulaLabelStatementCaseType(subIngredientStatementCase) : undefined
    }
    return api
      .get<FormulaIngredientStatementApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/ingredient_statement`,
        {
          params
        }
      )
      .then((res) => {
        return toFormulaIngredientStatement(res.data)
      })
  },
  updateIngredientStatement: (
    companyId: string,
    formulaId: string,
    data: UpdateFormulaIngredientStatementApiData
  ): Promise<FormulaIngredientStatement> => {
    return api
      .put<FormulaIngredientStatementApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/ingredient_statement`,
        data
      )
      .then((res) => {
        return toFormulaIngredientStatement(res.data)
      })
  },

  getFormulaNutrients: (
    companyId: string,
    formulaId: string,
    params: GetFormulaNutrientsApiParams
  ): Promise<FormulaNutrient[][]> => {
    return api
      .get<FormulaNutrientsApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/nutrients/display`,
        {
          params: params
        }
      )
      .then((res) => {
        return toFormulaNutrients(res.data)
      })
  },

  downloadFormulaNutrientsCSV: (
    companyId: string,
    formulaId: string,
    params: GetFormulaNutrientsCSVApiParams
  ): Promise<void> => {
    return api
      .get<Blob>(
        `/companies/${companyId}/formulas/${formulaId}/nutrients/csv`,
        {
          params: params,
          responseType: 'blob'
        }
      )
      .then((response) => {
        const contentDisposition = response.headers['contentDisposition']
        let filename = 'Nutrients.csv'

        if (contentDisposition) {
          filename = contentDisposition.split('filename=')[1].split(';')[0]
        }

        FileDownload(response.data, filename)
      })
  },
  getNutritionFacts: (
    companyId: string,
    formulaId: string,
    params?: GetFormulaNutritionFactsApiParams
  ): Promise<FormulaNutritionFact> => {
    return api
      .get<FormulaNutritionFactApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/nutrition_facts`,
        {
          params: params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return toFormulaNutritionFact(res.data)
      })
  },
  scaleFormula: (
    companyId: string,
    formulaId: string,
    data: UpdateFormulaWeightApiData
  ): Promise<number> => {
    return api
      .put<number>(`/companies/${companyId}/formulas/${formulaId}/scale`, data)
      .then(() => {
        return data.scale
      })
  },
  updateNutritionFacts: (
    companyId: string,
    formulaId: string,
    data: UpdateNutritionFactsApiData,
    params?: UpdateNutritionFactsApiParams
  ): Promise<FormulaNutritionFactsHeader> => {
    return api
      .put<FormulaNutritionFactsHeaderApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/nutrition_facts`,
        data,
        {
          params: {
            ...params,
            language: params
              ? fromFormulaNutritionFactLanguage(params.language)
              : undefined
          }
        }
      )
      .then((res) => {
        return toFormulaNutritionFactsHeader(res.data)
      })
  },
  addNutritionFactsOptionalNutrients: (
    companyId: string,
    formulaId: string,
    data: AddFormulaNutritionFactsOptionalNutrientsApiData
  ): Promise<Nutrient[]> => {
    return api
      .post<FormulaNutritionFactsOptionalNutrientsApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/nutrition_facts/optional_nutrients`,
        data
      )
      .then((res) => {
        return toNutrients(res.data.nutrients)
      })
  },
  removeNutritionFactsOptionalNutrients: (
    companyId: string,
    formulaId: string,
    params: RemoveFormulaNutritionFactsOptionalNutrientsApiParams
  ): Promise<void> => {
    return api.delete(
      `/companies/${companyId}/formulas/${formulaId}/nutrition_facts/optional_nutrients`,
      {
        params: params,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  },

  getFormulaAllergens: (
    companyId: string,
    formulaId: string,
    params: GetFormulaAllergensApiParams
  ): Promise<FormulaAllergens> => {
    return api
      .get<FormulaAllergensApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/allergens`,
        {
          params: params
        }
      )
      .then((res) => {
        return toFormulaAllergens(res.data)
      })
  },
  addFormulaAllergens: (
    companyId: string,
    formulaId: string,
    data: AddFormulaAllergensApiData
  ): Promise<FormulaAllergens> => {
    return api
      .post<FormulaAllergensApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/allergens`,
        data
      )
      .then((res) => {
        return toFormulaAllergens(res.data)
      })
  },
  removeFormulaAllergens: (
    companyId: string,
    formulaId: string,
    params: RemoveFormulaAllergensApiParams
  ): Promise<string[]> => {
    return api
      .delete(`/companies/${companyId}/formulas/${formulaId}/allergens`, {
        params: params,
        paramsSerializer: {
          indexes: null
        }
      })
      .then(() => {
        return params.allergenTypes
      })
  },

  uploadFormulaNoteDocument: async (
    companyId: string,
    formulaId: string,
    file: File,
    uploadProgressListener?: (
      progressEvent: AxiosProgressEvent
    ) => ProgressEvent | void
  ): Promise<string> => {
    return api
      .post<string>(
        `/companies/${companyId}/formulas/${formulaId}/notes/documents`,
        { fileName: file.name, contentType: file.type }
      )
      .then((res) => {
        return FileApi.uploadFile(file, res.data, uploadProgressListener)
      })
  },

  getFormulaNoteDocument: async (
    companyId: string,
    formulaId: string,
    fileName: string,
    download = true
  ): Promise<string> => {
    const encodedFileName = encodeURIComponent(fileName)

    return api
      .get<string>(
        `/companies/${companyId}/formulas/${formulaId}/notes/documents/${encodedFileName}`
      )
      .then((res) => {
        return FileApi.downloadFile(res.data, fileName, download)
      })
  },

  deleteFormulaNoteDocument: (
    companyId: string,
    formulaId: string,
    fileName: string
  ): Promise<string> => {
    return api
      .delete(`/companies/${companyId}/formulas/${formulaId}/notes/documents`, {
        params: { fileName }
      })
      .then(() => fileName)
  },

  getFormulaNoteDocuments: (
    companyId: string,
    formulaId: string
  ): Promise<BasicDocument[]> => {
    return api
      .get<DocumentApiResponse[]>(
        `/companies/${companyId}/formulas/${formulaId}/notes/documents`
      )
      .then((res) => {
        return toBasicDocuments(res.data)
      })
  },

  getFormulaYieldAdjustments: (
    companyId: string,
    formulaId: string
  ): Promise<FormulaYieldAdjustmentApiResponse[]> => {
    return api
      .get<FormulaYieldAdjustmentApiResponse[]>(
        `/companies/${companyId}/formulas/${formulaId}/yield_adjustments`
      )
      .then((res) => {
        return res.data
      })
  },

  addFormulaYieldAdjustment: (
    companyId: string,
    formulaId: string,
    yieldAdjustmentId: string,
    data: AddYieldAdjustmentApiData
  ): Promise<FormulaYieldAdjustmentApiResponse> => {
    return api
      .post<FormulaYieldAdjustmentApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/yield_adjustments/${yieldAdjustmentId}`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  removeFormulaYieldAdjustment: (
    companyId: string,
    formulaId: string,
    yieldAdjustmentId: string
  ): Promise<string> => {
    return api
      .delete(
        `/companies/${companyId}/formulas/${formulaId}/yield_adjustments/${yieldAdjustmentId}`
      )
      .then(() => {
        return yieldAdjustmentId
      })
  },

  updateFormulaYieldAdjustment: (
    companyId: string,
    formulaId: string,
    yieldAdjustmentId: string,
    data: UpdateYieldAdjustmentApiData
  ): Promise<FormulaYieldAdjustmentApiResponse> => {
    return api
      .put<FormulaYieldAdjustmentApiResponse>(
        `/companies/${companyId}/formulas/${formulaId}/yield_adjustments/${yieldAdjustmentId}`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  overrideFormulaNutrients: (
    companyId: string,
    formulaId: string,
    regulationId: string,
    data: NutrientOverrideApiData[]
  ): Promise<FormulaNutrientOverrideApiResponse[]> => {
    return api
      .put<FormulaNutrientOverrideApiResponse[]>(
        `/companies/${companyId}/formulas/${formulaId}/regulation/${regulationId}/nutrient/override`,
        data
      )
      .then((res) => {
        return res.data
      })
  },

  getFormulaCreatedByFilter: (
    companyId: string,
    params: GetFormulaCreatedByFilterApiParams
  ): Promise<FormulaCreatedByFilterApiResponse> => {
    return api
      .get<FormulaCreatedByFilterApiResponse>(
        `/companies/${companyId}/filters/formulas/created_by`,
        {
          params: params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return res.data
      })
  },

  getFormulaReport: (
    companyId: string,
    formulaId: string,
    params: GetFormulaReportApiParams,
    data: GetFormulaReportApiData
  ): Promise<string> => {
    const formData = new FormData()
    if (data.formulaLink) {
      formData.append('formulaLink', data.formulaLink)
    }
    if (data.nfpFile) {
      formData.append('nfpFile', data.nfpFile)
    }

    return api
      .post<string>(
        `/companies/${companyId}/formulas/${formulaId}/report`,
        formData,
        {
          params: params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return res.data
      })
  }
}
