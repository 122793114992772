import React from 'react'
import { NutritionFactsProps } from '../NutritionLabel'
import {
  Container,
  FlexContainerColumnSpaced
} from '../components/NutritionLabelStyles'
import {
  NutritionLabelLinearBox,
  NutritionLabelLinearContainer,
  NutritionLabelLinearFooter
} from './NutritionLabelLinearCanadianStyles'
import { ItemLinearContent } from './components/ItemLinearContent'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'
import { NutritionLabelTextCanadian } from '../components/NutritionLabelTextCanadian'

export const NutritionLabelLinearCanadian: React.FC<NutritionFactsProps> = ({
  servingsPerContainer,
  servingSize,
  servingWeight,
  topNutrients,
  bottomNutrients,
  calories
}) => {
  const NFPLabelLanguage = (language: number) => {
    return (
      <Container style={{ width: '500px' }}>
        <NutritionLabelLinearBox>
          <NutritionLabelLinearContainer>
            <div>
              <NutritionLabelTextCanadian bold points={10}>
                {language === 0 ? 'Nutrition Facts ' : 'Valeur nutritive '}
              </NutritionLabelTextCanadian>
              <NutritionLabelTextCanadian points={7.5}>
                {language === 0
                  ? `per ${
                      servingSize[
                        FormulaNutritionFactLanguage.ENGLISH as FormulaNutritionFactLanguage
                      ] || ''
                    }`
                  : `pour ${
                      servingSize[
                        FormulaNutritionFactLanguage.FRENCH as FormulaNutritionFactLanguage
                      ] || ''
                    }`}{' '}
                ({servingWeight}){' : '}
              </NutritionLabelTextCanadian>
              <NutritionLabelTextCanadian bold points={8}>
                Calories {calories}
              </NutritionLabelTextCanadian>
            </div>
            <ItemLinearContent
              itemsLinearProps={[...topNutrients, ...bottomNutrients].map(
                (fnfn) => {
                  return {
                    nutrientType: fnfn.nutrient.type,
                    itemLinearValues: {
                      name:
                        language === 0
                          ? fnfn.displayNameOptions[
                              FormulaNutritionFactLanguage.ENGLISH
                            ] || ''
                          : fnfn.displayNameOptions[
                              FormulaNutritionFactLanguage.FRENCH
                            ] ||
                            fnfn.displayNameOptions[
                              FormulaNutritionFactLanguage.ENGLISH
                            ] ||
                            '', // English if french is not present.
                      amount: fnfn.displayAmountOptions[0]?.amount,
                      unit: fnfn.nutrient.unit,
                      dvPercentage: fnfn.displayDvOptions[0]?.amount
                    }
                  }
                }
              )}
            />
          </NutritionLabelLinearContainer>
          <NutritionLabelLinearFooter>
            {language === 0 && (
              <NutritionLabelTextCanadian alignRight points={6}>
                % = % Daily Value
                <NutritionLabelTextCanadian symbol={true} points={9}>
                  *
                </NutritionLabelTextCanadian>
              </NutritionLabelTextCanadian>
            )}
            {language === 1 && (
              <NutritionLabelTextCanadian alignRight points={6}>
                % = % valeur quotidienne
                <NutritionLabelTextCanadian symbol={true} points={9}>
                  *
                </NutritionLabelTextCanadian>
              </NutritionLabelTextCanadian>
            )}
            {language === 0 && (
              <NutritionLabelTextCanadian points={6}>
                *5% or less is <b>a little</b>, 15% or more is <b>a lot</b>
              </NutritionLabelTextCanadian>
            )}
            {language === 1 && (
              <NutritionLabelTextCanadian points={6}>
                *5% ou moins c'est <b>peu</b>, 15% ou plus c'est <b>beaucoup</b>
              </NutritionLabelTextCanadian>
            )}
          </NutritionLabelLinearFooter>
        </NutritionLabelLinearBox>
      </Container>
    )
  }

  return (
    <FlexContainerColumnSpaced style={{ gap: '20px' }}>
      {NFPLabelLanguage(0)}
      {NFPLabelLanguage(1)}
    </FlexContainerColumnSpaced>
  )
}
