import React from 'react'
import { Box, Popover, Typography, useTheme, Divider } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppSelector } from 'state/hooks'
import { ModalContext } from '../Modal/ModalContext'
import { SnackbarContext } from '../Snackbar/SnackbarContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { Button } from 'components/Button/Button'
import { ButtonTextColors } from 'styles/colors'

interface Brand {
  name: string
  initials: string
}

interface BrandCardProps {
  brand: Brand
  color: string
  clickable: boolean
}

interface BrandIconProps {
  brand: Brand
  color: string
  callback?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const BrandIcon: React.FC<BrandIconProps> = ({ brand, color, callback }) => {
  const theme = useTheme()
  return (
    <Button
      variant="square"
      sx={{
        backgroundColor: color,
        color: ButtonTextColors.primary,
        '&:hover': {
          backgroundColor: ButtonTextColors.primary,
          color: color
        },
        textTransform: 'uppercase'
      }}
      size="large"
      onClick={callback}
    >
      <Typography sx={{ fontHeight: '24' }}>{brand.initials}</Typography>
    </Button>
  )
}

const BrandCard: React.FC<BrandCardProps> = ({ brand, color, clickable }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '8px',
        padding: '4px',
        border: `1px solid transparent`,
        '&:hover': {
          backgroundColor: theme.palette.accentHighlight.main,
          border: `1px solid rgba(6, 179, 234, 0.4)`
        },
        pointerEvents: clickable ? 'auto' : 'none'
      }}
      alignItems="center"
      gap="12px"
    >
      <BrandIcon brand={brand} color={color} />
      <Typography>{brand.name}</Typography>
    </Box>
  )
}

export const BrandSwitcher: React.FC = () => {
  const theme = useTheme()
  const { logout } = useAuth0()
  const orange = theme.palette.brandSwitcher.orange.main
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [curBrand, setCurBrand] = React.useState<Brand>({
    name: '',
    initials: ''
  })
  const [brands, setBrands] = React.useState<Brand[]>([curBrand])
  const company = useAppSelector((state) => state.companies.currentCompany)
  const user = useAppSelector((state) => state.users.currentUser)
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { showError } = React.useContext(SnackbarContext)
  const { generalAnalytics } = React.useContext(AnalyticsContext)

  const handleLogout = () => {
    handleClose()
    showConfirmationModal({
      title: 'Log out',
      message: 'Are you sure you want to log out?',
      yesText: 'Log out',
      noText: 'Cancel',
      onYesClicked: () => {
        generalAnalytics.loggedOut()
        logout().catch(() => {
          showError(`Failed to log out`)
        })
      }
    })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  /**
   * make brand from company
   * brand & company same?
   * future: user may have multiple brands, user may switch between brands
   */
  React.useEffect(() => {
    if (company.name !== '') {
      const brnd: Brand = {
        name: company.name,
        initials: company.name
          .split(' ')
          .map((val, i) => (i < 2 ? val.charAt(0) : ''))
          .join('')
      }
      setCurBrand(brnd)
      setBrands([brnd])
    }
  }, [company])

  return (
    <div>
      <BrandIcon color={orange} brand={curBrand} callback={handleClick} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 60
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box sx={{ margin: '8px' }}>
          {brands.map((brand: Brand, i, arr: Brand[]) => (
            <BrandCard
              brand={brand}
              color={orange}
              clickable={arr.length > 1}
              key={brand.name}
            />
          ))}
          <Divider variant="middle" />
          <Button
            onClick={handleLogout}
            variant="text"
            color="danger"
            fullWidth
          >
            Log Out
          </Button>
        </Box>
      </Popover>
    </div>
  )
}
