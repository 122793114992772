import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Conversion, IngredientConversions } from 'models/Conversion'
import { Measurement, MeasurementState, MeasurementType } from 'models/Measurement'
import { ConversionsApi } from 'services/apis/conversions/ConversionApi'
import { MeasurementsApi } from 'services/apis/measurements/MeasurementApi'
import {
  AddingState,
  DeletingState,
  LoadingState,
  UpdatingState
} from '../../CommonState'
import {
  AddConversionRequest,
  GetConversionsRequest,
  GetMeasurementsRequest,
  RemoveConversionRequest,
  UpdateConversionRequest
} from './SimpleIngredientMeasurementsRequest'

export interface SimpleIngredientMeasurementsState
  extends LoadingState,
  DeletingState,
  UpdatingState,
  AddingState {
  conversions?: Conversion[]
  measurements?: Measurement[]
  liquidMeasurements?: Measurement[]
  solidMeasurements?: Measurement[]
}

const initialState: SimpleIngredientMeasurementsState = {
  conversions: undefined,
  measurements: undefined,
  liquidMeasurements: undefined,
  solidMeasurements: undefined,
  loading: false,
  updating: false,
  deleting: false,
  adding: false,
  error: false
}

export const getMeasurements = createAsyncThunk(
  'measurements/all/get',
  async ({ companyId }: GetMeasurementsRequest) => {
    return await MeasurementsApi.getMeasurements(companyId, {
      withPublicMeasurements: true
    })
  }
)

export const getLiquidMeasurements = createAsyncThunk(
  'measurements/liquid/get',
  async ({ companyId }: GetMeasurementsRequest) => {
    return await MeasurementsApi.getMeasurements(companyId, {
      withPublicMeasurements: true,
      state: MeasurementState.LIQUID
    })
  }
)

export const getSolidMeasurements = createAsyncThunk(
  'measurements/solid/get',
  async ({ companyId }: GetMeasurementsRequest) => {
    return await MeasurementsApi.getMeasurements(companyId, {
      withPublicMeasurements: true,
      state: MeasurementState.SOLID
    })
  }
)

export const getConversions = createAsyncThunk(
  'simple_ingredients/conversions/all/get',
  async ({ companyId, simpleIngredientId }: GetConversionsRequest) => {
    return await ConversionsApi.getConversions(companyId, simpleIngredientId)
  }
)

export const addConversion = createAsyncThunk(
  'simple_ingredients/conversions/add',
  async ({
    companyId,
    simpleIngredientId,
    measurementId,
    amountInMeasurement = 1,
    amount = 0
  }: AddConversionRequest) => {
    // TODO: Needs to return Conversion from backend.
    return await ConversionsApi.createConversion(
      companyId,
      simpleIngredientId,
      {
        measurementId,
        amountInMeasurement: amountInMeasurement,
        equivalentInGrams: amount
      }
    )
  }
)

export const updateConversion = createAsyncThunk(
  'simple_ingredients/conversions/update',
  async ({
    companyId,
    simpleIngredientId,
    conversionId,
    amount,
    amountInMeasurement
  }: UpdateConversionRequest) => {
    // TODO: Update needs to accept optional measurementId.
    return await ConversionsApi.updateConversion(
      companyId,
      simpleIngredientId,
      conversionId,
      {
        equivalentInGrams: amount,
        amountInMeasurement
      }
    )
  }
)

export const removeConversion = createAsyncThunk(
  'simple_ingredients/conversions/remove',
  async ({
    companyId,
    simpleIngredientId,
    conversionIds
  }: RemoveConversionRequest) => {
    return ConversionsApi.deleteConversions(companyId, simpleIngredientId, {
      conversionIds
    }).then(() => {
      return conversionIds
    })
  }
)

const SimpleIngredientMeasurementSlice = createSlice({
  name: 'simpleIngredientMeasurementSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //
    builder
      .addCase(getMeasurements.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getMeasurements.fulfilled,
        (state, action: PayloadAction<Measurement[]>) => {
          state.measurements = action.payload
          state.loading = false
        }
      )
      .addCase(getMeasurements.rejected, (state) => {
        state.loading = false
        state.error = true
      })

    builder
      .addCase(getLiquidMeasurements.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getLiquidMeasurements.fulfilled,
        (state, action: PayloadAction<Measurement[]>) => {
          state.liquidMeasurements = action.payload
          state.loading = false
        }
      )
      .addCase(getLiquidMeasurements.rejected, (state) => {
        state.loading = false
        state.error = true
      })

      builder
        .addCase(getSolidMeasurements.pending, (state) => {
          state.loading = true
        })
        .addCase(
          getSolidMeasurements.fulfilled,
          (state, action: PayloadAction<Measurement[]>) => {
            state.solidMeasurements = action.payload
            state.loading = false
          }
        )
        .addCase(getSolidMeasurements.rejected, (state) => {
          state.loading = false
          state.error = true
        })
        
    builder
      .addCase(getConversions.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getConversions.fulfilled,
        (state, action: PayloadAction<IngredientConversions>) => {
          state.conversions = [...action.payload.conversions]
          state.loading = false
        }
      )
      .addCase(getConversions.rejected, (state) => {
        state.loading = false
        state.error = true
      })

    builder
      .addCase(addConversion.pending, (state) => {
        state.adding = true
      })
      .addCase(
        addConversion.fulfilled,
        (state, action: PayloadAction<Conversion>) => {
          if (state.conversions) {
            state.conversions = [...state.conversions, action.payload]
          }
          state.adding = false
        }
      )
      .addCase(addConversion.rejected, (state) => {
        state.adding = false
        state.error = true
      })

    builder
      .addCase(updateConversion.pending, (state) => {
        state.updating = true
      })
      .addCase(
        updateConversion.fulfilled,
        (state, action: PayloadAction<Conversion>) => {
          if (state.conversions) {
            state.conversions = state.conversions.map((c) => {
              if (action.payload.id === c.id) {
                return action.payload
              } else {
                return c
              }
            })
          }
          state.updating = false
        }
      )
      .addCase(updateConversion.rejected, (state) => {
        state.updating = false
        state.error = true
      })
    builder
      .addCase(removeConversion.pending, (state) => {
        state.deleting = true
      })
      .addCase(removeConversion.fulfilled, (state, action) => {
        if (state.conversions) {
          state.conversions = state.conversions.filter((c) =>
            action.payload.every((conversionId) => c.id !== conversionId)
          )
        }
        state.deleting = false
      })
      .addCase(removeConversion.rejected, (state) => {
        state.deleting = false
        state.error = true
      })
  }
})

export default SimpleIngredientMeasurementSlice.reducer
