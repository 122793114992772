import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { FormulaTableHeaderCell } from 'pages/Formulator/FormulatorStyles'
import React from 'react'
import {
  IngredientRow,
  IngredientRowProps
} from './components/row/IngredientRow'
import { BoxColumnBasic } from 'components/common'

export interface IngredientTableProps {
  showSupplier?: boolean
  showCost?: boolean
  showWastePercentage?: boolean
  showQuantityPercentage?: boolean
  loading?: boolean
  ingredientSearchRow: React.ReactNode
  ingredientRows?: IngredientRowProps[]
}

export const IngredientsTable: React.FC<IngredientTableProps> = ({
  showSupplier = false,
  showCost = false,
  showWastePercentage = false,
  showQuantityPercentage = false,
  loading = false,
  ingredientSearchRow,
  ingredientRows = []
}) => {
  const tableCellPadding = { padding: '0px 8px' }

  // TODO: Handle sorting.

  const allIngredientsChecked = React.useMemo(() => {
    return ingredientRows.length > 0 && ingredientRows.every((ir) => ir.checked)
  }, [ingredientRows])

  const handleCheckAllIngredientsChange = React.useCallback(() => {
    const checked = allIngredientsChecked
    ingredientRows.forEach((ir) => {
      ir.onRowCheckedChange(!checked)
    })
  }, [ingredientRows, allIngredientsChecked])

  const getPlaceholderRows = (rows: number) => {
    const cellCount = 3
    return (
      <>
        {Array.from({ length: rows }).map((_, rowIdx) => (
          <TableRow key={rowIdx}>
            {Array.from({ length: cellCount }).map((_, cellIdx) => (
              <TableCell key={cellIdx} sx={{ ...tableCellPadding }}>
                <Skeleton
                  variant="rectangular"
                  height={'32px'}
                  sx={{ marginTop: '3px', marginBottom: '3px' }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    )
  }

  return (
    <BoxColumnBasic>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <FormulaTableHeaderCell sx={{ width: '40px' }} key="check">
              <Checkbox
                size="small"
                checked={allIngredientsChecked}
                onChange={() => handleCheckAllIngredientsChange()}
              />
            </FormulaTableHeaderCell>
            <FormulaTableHeaderCell key="ingredient" sx={{ width: '65%' }}>
              <div data-sortable>Ingredient</div>
            </FormulaTableHeaderCell>
            {showSupplier && (
              <FormulaTableHeaderCell key="supplier" sx={{ width: '35%' }}>
                <div data-sortable>Supplier</div>
              </FormulaTableHeaderCell>
            )}
            {showCost && (
              <FormulaTableHeaderCell
                key="cost"
                sx={{
                  width: '100px'
                }}
                align="left"
              >
                <div data-sortable>Cost ($)</div>
              </FormulaTableHeaderCell>
            )}
            {showWastePercentage && (
              <FormulaTableHeaderCell
                key="wastePercentages"
                sx={{
                  width: '100px'
                }}
                align="left"
              >
                <div data-sortable>Waste %</div>
              </FormulaTableHeaderCell>
            )}
            <FormulaTableHeaderCell
              key="quantity"
              sx={{
                width: showQuantityPercentage ? '280px' : '180px'
              }}
              align="right"
            >
              <div data-sortable>Quantity</div>
            </FormulaTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? getPlaceholderRows(1) : ingredientSearchRow}
        </TableBody>
      </Table>
      <Table sx={{ tableLayout: 'fixed' }}>
        <TableHead sx={{ height: 0 }}>
          <TableRow>
            <TableCell sx={{ width: '40px', padding: 0 }} key="check" />
            <TableCell key="ingredient" sx={{ width: '65%', padding: 0 }} />
            {showSupplier && (
              <TableCell key="supplier" sx={{ width: '35%', padding: 0 }} />
            )}
            {showCost && (
              <TableCell key="cost" sx={{ width: '100px', padding: 0 }} />
            )}
            {showWastePercentage && (
              <TableCell
                key="wastePercentage"
                sx={{ width: '100px', padding: 0 }}
              />
            )}
            <TableCell
              key="quantity"
              sx={{
                width: showQuantityPercentage ? '280px' : '180px',
                padding: 0
              }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? getPlaceholderRows(5)
            : ingredientRows.map((ir) => (
                <IngredientRow key={`ir-${ir.formulaIngredientId}`} {...ir} />
              ))}
        </TableBody>
      </Table>
    </BoxColumnBasic>
  )
}
