import { FormulaNutritionFactLanguage, FormulaNutritionFactNutrient, FormulaNutritionFactNutrientLabelDisplay } from 'models/FormulaLabel'
import { ItemTableContent } from '../../components/ItemTableContent'

export const BottomNutrientsTable: React.FC<{
  bottomNutrients: FormulaNutritionFactNutrientLabelDisplay[]
}> = ({ bottomNutrients = [] }) => (
  <>
    <ItemTableContent
      tableItemRowsProps={bottomNutrients.map((fnfn) => {
        return {
          nutrientType: fnfn.nutrient.type,
          itemRowValues: {
            name: fnfn.displayNameOptions[FormulaNutritionFactLanguage.ENGLISH] || '',
            amount: fnfn.displayAmountOptions[0]?.amount,
            unit: fnfn.nutrient.unit,
            dvPercentage: fnfn.displayDvOptions[0]?.amount
          }
        }
      })}
    />
  </>
)
