import {
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient,
  FormulaNutritionFactNutrientLabelDisplay
} from 'models/FormulaLabel'
import { NutrientType } from 'models/Nutrient'
import React from 'react'
import { NutritionLabelText } from '../../components/NutritionLabelText'
import { NutrientsTableBottomContainer } from '../NutritionLabelTabularStyles'

const getBottomNutrientsText = (
  name: string,
  amount: string,
  unit: string,
  dvPercentage: string,
  nutrientType: NutrientType
) => {
  if (nutrientType === NutrientType.FOLATE_DFE) {
    return (
      `Folate ${amount}${unit} DFE` + (dvPercentage ? ` ${dvPercentage}%` : '')
    )
  }
  return `${name} ${amount}${unit}` + (dvPercentage ? ` ${dvPercentage}%` : '')
}

export const BottomNutrientsText: React.FC<{
  bottomNutrients: FormulaNutritionFactNutrientLabelDisplay[]
}> = ({ bottomNutrients = [] }) => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={{ marginLeft: '-10px' }}>
        <NutrientsTableBottomContainer>
          {bottomNutrients.map((fnfn, index) => (
            <div key={`bottomNutrients-${index}`}>
              <NutritionLabelText points={8}>
                <>&nbsp;• </>
                {getBottomNutrientsText(
                  fnfn.displayNameOptions[
                    FormulaNutritionFactLanguage.ENGLISH
                  ] || '',
                  fnfn.displayAmountOptions[0]?.amount,
                  fnfn.nutrient.unit,
                  fnfn.displayDvOptions[0]?.amount,
                  fnfn.nutrient.type
                )}
              </NutritionLabelText>
            </div>
          ))}
        </NutrientsTableBottomContainer>
      </div>
    </div>
  )
}
