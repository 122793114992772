import { ItemRowValues } from 'components/NutritionLabel/components/ItemRow'
import {
  FlexContainerColumn,
  FlexContainerColumnCentered,
  FlexContainerItemRow,
  FlexContainerRow,
  POINT_TO_PIXEL_RATIO
} from 'components/NutritionLabel/components/NutritionLabelStyles'
import { NutritionLabelTextCanadian } from 'components/NutritionLabel/components/NutritionLabelTextCanadian'
import { INDENTATION_POINTS } from './ItemRow'

export interface ItemRowSettings {
  level?: number
  boldName?: boolean
  boldPercentage?: boolean
  topLine?: boolean
}

export interface FatItemRowProps extends ItemRowSettings {
  saturated: ItemRowValues
  trans: ItemRowValues
}

export const FatItemRow: React.FC<FatItemRowProps> = ({
  saturated,
  trans,
  level = 0,
  boldName = false,
  boldPercentage = false
}) => {
  return (
    <>
      <FlexContainerItemRow
        style={{
          paddingLeft: `${level * INDENTATION_POINTS * POINT_TO_PIXEL_RATIO}px`,
          alignItems: 'center'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          <FlexContainerColumn>
            <FlexContainerRow>
              <NutritionLabelTextCanadian
                bold={boldName}
                points={8}
                additionalLineHeight={1}
              >
                {saturated.name}
              </NutritionLabelTextCanadian>
              <NutritionLabelTextCanadian points={8} additionalLineHeight={1}>
                {'\u00A0'}
                {saturated.amount}
                {'\u00A0'}
                {saturated.unit}
              </NutritionLabelTextCanadian>
            </FlexContainerRow>
            <FlexContainerRow>
              <NutritionLabelTextCanadian
                bold={boldName}
                points={8}
                additionalLineHeight={1}
              >
                + {trans.name}
              </NutritionLabelTextCanadian>
              <NutritionLabelTextCanadian points={8} additionalLineHeight={1}>
                {'\u00A0'}
                {trans.amount}
                {'\u00A0'}
                {trans.unit}
              </NutritionLabelTextCanadian>
            </FlexContainerRow>
          </FlexContainerColumn>
        </div>
        <div>
          <FlexContainerColumnCentered>
            <NutritionLabelTextCanadian
              bold={boldPercentage}
              points={8}
              additionalLineHeight={1}
            >
              {trans.dvPercentage &&
                saturated.dvPercentage &&
                `${(
                  Number(trans.dvPercentage) + Number(saturated.dvPercentage)
                ).toFixed(0)} %`}
            </NutritionLabelTextCanadian>
          </FlexContainerColumnCentered>
        </div>
      </FlexContainerItemRow>
    </>
  )
}
