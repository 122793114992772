import React from 'react'
import { NutrientsSettings, NutrientsSettingsProps } from './NutrientsSettings'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setNutritionFactLabelEdits } from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { FormulaNutritionFactLabelAgeGroup } from 'models/FormulaLabel'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import { Option } from 'components/common'

export const SHOW_PROTEIN_PERCENTAGE = 'showProteinPercentage'
export const APPLY_PDCAAS = 'applyPdcaas'
export const PDCAAS_SCORE = 'pdcaasScore'
export const DV_BASED_ON_ROUNDED = 'dvBasedOnRounded'

export const NutrientsSettingsContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )
  const showProteinPercentage = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.showProteinPercentage
  )
  const applyPdcaas = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.applyPdcaas
  )
  const pdcaas = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.pdcaas
  )
  const dvBasedOnRounded = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.dvBasedOnRounded
  )
  const category = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.ageGroup
  )
  const regulations = useAppSelector(
    (state) => state.nutritionFactLabels.availableRegulations
  )
  const selectedRegulationId = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.regulationId
  )

  const nutrientsSettings: NutrientsSettingsProps = {
    showProteinPercentage: {
      label: 'Show Protein %',
      value: showProteinPercentage,
      hidden:
        regulations.find((regulation) => regulation.id === selectedRegulationId)
          ?.name === RegulationName.CANADA,
      disabled: category === FormulaNutritionFactLabelAgeGroup.INFANT,
      onChange: (v) => handleSwitchChange(SHOW_PROTEIN_PERCENTAGE, v)
    },
    pdcaas: {
      applyPdcaas: {
        label: 'Use PDCAAS',
        value: applyPdcaas,
        hidden: false,
        disabled: false,
        onChange: (v) => handleSwitchChange(APPLY_PDCAAS, v)
      },
      pdcaasScore: {
        label: 'PDCAAS Score',
        value: pdcaas.toString(),
        hidden: false,
        disabled: false,
        onChange: (v) => onFieldChange(PDCAAS_SCORE, v),
        validator: (v) => {
          const num = parseFloat(v)
          return !isNaN(num) && num >= 0 && num <= 1
        }
      }
    },
    dvBasedOnRounded: {
      label: 'Calculate %DV Based on Rounded Values',
      value: dvBasedOnRounded,
      hidden: false,
      disabled: false,
      onChange: (v) => handleSwitchChange(DV_BASED_ON_ROUNDED, v)
    }
  }

  React.useEffect(() => {
    if (category === FormulaNutritionFactLabelAgeGroup.INFANT) {
      if (!showProteinPercentage) {
        handleSwitchChange(SHOW_PROTEIN_PERCENTAGE, true)
      }
    }
  }, [category])

  const handleSwitchChange = React.useCallback(
    (id: string, checked: boolean) => {
      if (id === SHOW_PROTEIN_PERCENTAGE) {
        void dispatch(
          setNutritionFactLabelEdits({
            ...nutritionFactLabelEdits,
            nutritionFactLabelPreview: {
              ...nutritionFactLabelEdits.nutritionFactLabelPreview,
              showProteinPercentage: checked
            }
          })
        )
      }
      if (id === APPLY_PDCAAS) {
        void dispatch(
          setNutritionFactLabelEdits({
            ...nutritionFactLabelEdits,
            nutritionFactLabelPreview: {
              ...nutritionFactLabelEdits.nutritionFactLabelPreview,
              applyPdcaas: checked
            }
          })
        )
      }
      if (id === DV_BASED_ON_ROUNDED) {
        void dispatch(
          setNutritionFactLabelEdits({
            ...nutritionFactLabelEdits,
            nutritionFactLabelPreview: {
              ...nutritionFactLabelEdits.nutritionFactLabelPreview,
              dvBasedOnRounded: checked
            }
          })
        )
      }
    },
    [nutritionFactLabelEdits]
  )

  const onFieldChange = React.useCallback(
    (id: string, value: string) => {
      if (id === PDCAAS_SCORE) {
        void dispatch(
          setNutritionFactLabelEdits({
            ...nutritionFactLabelEdits,
            nutritionFactLabelPreview: {
              ...nutritionFactLabelEdits.nutritionFactLabelPreview,
              pdcaas: parseFloat(value)
            }
          })
        )
      }
    },
    [nutritionFactLabelEdits]
  )

  return (
    <NutrientsSettings
      showProteinPercentage={nutrientsSettings.showProteinPercentage}
      pdcaas={{
        applyPdcaas: nutrientsSettings.pdcaas.applyPdcaas,
        pdcaasScore: nutrientsSettings.pdcaas.pdcaasScore
      }}
      dvBasedOnRounded={nutrientsSettings.dvBasedOnRounded}
    />
  )
}
