import { ModalContext } from 'components/Modal/ModalContext'
import React from 'react'
import { YieldAdjustmentType } from 'services/apis/formula/FormulaApiResponse'
import {
  getFormulaYieldAdjustments,
  removeFormulaYieldAdjustment,
  updateFormulaYieldAdjustment
} from 'state/formulator/yieldAdjustments/FormulaYieldAdjustmentsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { YieldAdjustments } from './YieldAdjustments'
import { convertToFixedFloat } from 'common/utils'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'

export const YieldAdjustmentsContainer: React.FC = () => {
  const formulaYieldAdjustments = useAppSelector(
    (state) => state.formulaYieldAdjustmentsSlice.formulaYieldAdjustments
  )
  const currentCompanyId = useAppSelector(
    (state) => state.companies.currentCompany.id
  )
  const currentFormulaId = useAppSelector(
    (state) => state.formulator.formula.id
  )
  const { showInfoModal, showConfirmationModal } =
    React.useContext(ModalContext)

  const dispatch = useAppDispatch()
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  const handleYieldAdjustmentRemove = React.useCallback(
    (id: string) => {
      // Find the yield name.
      const yieldName = formulaYieldAdjustments.find(
        (fya) => fya.yieldAdjustment.id === id
      )?.yieldAdjustment.name

      showConfirmationModal({
        title: 'Remove Yield Adjustment',
        message: (
          <>
            Are you sure you want to remove {<b>{yieldName}</b>} from this
            formula?
          </>
        ),
        yesText: 'Remove',
        noText: 'Cancel',
        onYesClicked: () => {
          void dispatch(
            removeFormulaYieldAdjustment({
              companyId: currentCompanyId,
              formulaId: currentFormulaId,
              yieldAdjustmentId: id
            })
          )
          formulatorAnalytics.ingredients.removedYieldAdjustment(
            currentFormulaId
          )
        },
        danger: true
      })
    },
    [currentCompanyId, currentFormulaId]
  )

  const handleYieldAdjustmentUpdate = React.useCallback(
    (
      id: string,
      amount?: number,
      percentage?: number,
      adjustmentType?: YieldAdjustmentType
    ) => {
      // Find the yield max amount for this adjustment.
      const maxYieldAmount = formulaYieldAdjustments.find(
        (fya) => fya.yieldAdjustment.id === id
      )?.maxYieldAmount

      if (amount && maxYieldAmount && amount > maxYieldAmount) {
        showInfoModal({
          title: 'Maximum Yield Amount',
          message: (
            <>
              The amount you entered exceeds the maximum yield amount of{' '}
              <b>{convertToFixedFloat(maxYieldAmount, 2)}g</b>. Please enter a
              lower amount.
            </>
          ),
          yesText: 'OK'
        })
      } else {
        void dispatch(
          updateFormulaYieldAdjustment({
            companyId: currentCompanyId,
            formulaId: currentFormulaId,
            yieldAdjustmentId: id,
            yieldAmount: amount,
            yieldPercentage: percentage,
            adjustmentType
          })
        ).then(() => {
          void dispatch(
            getFormulaYieldAdjustments({
              companyId: currentCompanyId,
              formulaId: currentFormulaId
            })
          )
        })
        formulatorAnalytics.ingredients.modifiedYieldAdjustment(
          currentFormulaId
        )
      }
    },
    [currentCompanyId, currentFormulaId, formulaYieldAdjustments]
  )

  const adjustments = React.useMemo(() => {
    return formulaYieldAdjustments.map((fya) => {
      return {
        adjustment: {
          id: fya.yieldAdjustment.id,
          name: fya.yieldAdjustment.name
        },
        adjustmentTypeOptions: [
          {
            id: YieldAdjustmentType.LOSS,
            label: 'Loss',
            value: fya.adjustmentType === YieldAdjustmentType.LOSS
          },
          {
            id: YieldAdjustmentType.TARGET,
            label: 'Target',
            value: fya.adjustmentType === YieldAdjustmentType.TARGET
          }
        ],
        adjustmentPercentage: fya.yieldPercentage,
        adjustmentAmount: fya.yieldAmount,
        onChange: (
          amount?: number,
          percentage?: number,
          adjustmentType?: string
        ) =>
          handleYieldAdjustmentUpdate(
            fya.yieldAdjustment.id,
            amount,
            percentage,
            adjustmentType as YieldAdjustmentType
          ),
        onRemove: () => handleYieldAdjustmentRemove(fya.yieldAdjustment.id)
      }
    })
  }, [formulaYieldAdjustments])

  return <YieldAdjustments adjustments={adjustments} />
}

export default YieldAdjustmentsContainer
