import { Box, Typography } from '@mui/material'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import {
  AllergenDisplayNameLanguage,
  AllergensPerLanguage
} from 'models/Allergen'
import { FormulaNutritionFactLabelLangSpecificFields } from 'models/FormulaLabel'
import React from 'react'

export interface AllergensStatementProps {
  allergens: AllergensPerLanguage
  statementsOverride: FormulaNutritionFactLabelLangSpecificFields
  suggestedAllergenStatement: FormulaNutritionFactLabelLangSpecificFields
  allergenStatementPrefix: FormulaNutritionFactLabelLangSpecificFields
}

export const AllergensStatement: React.FC<AllergensStatementProps> = ({
  allergens,
  statementsOverride,
  suggestedAllergenStatement,
  allergenStatementPrefix
}) => {
  return (
    <Box width="100%">
      {Object.keys(allergens).map((languageKey) => {
        const language = languageKey as AllergenDisplayNameLanguage
        if (
          !statementsOverride[language] &&
          !suggestedAllergenStatement[language]
        ) {
          return null
        }
        return (
          <MarkdownRenderer
            key={language}
            markdownText={`${allergenStatementPrefix[language]} ${
              statementsOverride[language] ||
              suggestedAllergenStatement[language] ||
              ''
            }`}
          />
        )
      })}
    </Box>
  )
}
