import { NoPreview } from 'components/NoPreview/NoPreview'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { TabContent } from '../TabContent'

import { FormulaNutrientsScale } from 'models/Formula'
import { useMatch } from 'react-router'
import { getFormulaNutrients, setScale } from 'state/formulator/FormulatorSlice'
import { toTableNutrientsRowGroup } from './NutrientsMapper'
import NutrientsBasedOn from './components/NutrientsBasedOn/NutrientsBasedOn'
import NutrientsTable from './components/NutrientsTable/NutrientsTable'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'

export const Nutrients: React.FC = () => {
  const dispatch = useAppDispatch()
  const formulaId = useMatch('/formulas/:id')?.params.id as string

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaNutrients = useAppSelector(
    (state) => state.formulator.formulaNutrients
  )
  const formulaIngredients = useAppSelector(
    (state) => state.formulatorIngredients.formulaIngredients
  )
  const servingWeight = useAppSelector(
    (state) => state.formulator.formula.servingWeight
  )

  const formulaYieldAdjustments = useAppSelector(
    (state) => state.formulaYieldAdjustmentsSlice.formulaYieldAdjustments
  )

  const scale = useAppSelector((state) => state.formulator.scale)
  const ingredientsAndScaleLoaded = React.useRef(false)
  const servingWeightLoaded = React.useRef(false)
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  React.useEffect(() => {
    if (formulaId) {
      formulatorAnalytics.nutrients.viewedFormulatorNutrientsTab(formulaId)
    }
  }, [formulaId])

  /**
   * Initial loading of nutrients on formula id change.
   */
  React.useEffect(() => {
    if (formulaId) {
      void dispatch(
        getFormulaNutrients({
          companyId: currentCompany.id,
          formulaId: formulaId,
          scale: scale
        })
      )
    }
  }, [formulaId, formulaYieldAdjustments])

  React.useEffect(() => {
    if (
      formulaId &&
      scale == FormulaNutrientsScale.SERVING_SIZE &&
      servingWeightLoaded.current
    ) {
      void dispatch(
        getFormulaNutrients({
          companyId: currentCompany.id,
          formulaId: formulaId,
          scale: scale
        })
      )
    }

    if (servingWeight !== undefined && !servingWeightLoaded.current) {
      servingWeightLoaded.current = true
    }
  }, [servingWeight])

  const nutrientTableRowGroups = React.useMemo(() => {
    if (formulaNutrients) {
      return toTableNutrientsRowGroup(formulaNutrients)
    }
    return []
  }, [formulaNutrients])

  React.useEffect(() => {
    if (formulaId && ingredientsAndScaleLoaded.current) {
      void dispatch(
        getFormulaNutrients({
          companyId: currentCompany.id,
          formulaId: formulaId,
          scale: scale
        })
      )
    }

    if (
      formulaIngredients !== undefined &&
      scale &&
      !ingredientsAndScaleLoaded.current
    ) {
      ingredientsAndScaleLoaded.current = true
    }
  }, [formulaIngredients, scale])

  const handleScaleChanged = React.useCallback(
    (scale: FormulaNutrientsScale) => {
      dispatch(setScale(scale))
    },
    [dispatch]
  )

  return (
    <>
      {formulaIngredients !== undefined && formulaIngredients.length === 0 && (
        <NoPreview text="Add an ingredient to your formula to get a preview of your formula nutrients" />
      )}
      {(formulaIngredients === undefined || formulaIngredients.length > 0) && (
        <TabContent
          padding="0px"
          footerElement={
            <NutrientsBasedOn
              initialScale={scale}
              onScaleChangedCallback={handleScaleChanged}
            />
          }
        >
          <NutrientsTable nutrientTableRowGroups={nutrientTableRowGroups} />
        </TabContent>
      )}
    </>
  )
}

export default Nutrients
