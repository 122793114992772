import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  Formula,
  FormulaNutrient,
  FormulaNutrientsScale,
  FormulaRegulationSettingsEdits,
  FormulaStatus
} from 'models/Formula'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import { fromFormulaLabelStatementCaseType, fromFormulaStatus } from 'services/apis/formula/FormulaApiMapper'
import { RootState } from '../store'
import {
  DeleteFormulaRequest,
  DownloadFormulaNutrientsCSVRequest,
  DuplicateFormulaRequest,
  GetFormulaNutrientRequest,
  UpdateFormulaArchiveRequest,
  UpdateFormulaFriendlyIdRequest,
  UpdateFormulaNameRequest,
  UpdateFormulaNoteRequest,
  UpdateFormulaRegulationIsMealOrMainDishRequest,
  UpdateFormulaRegulationRaccRequest,
  UpdateFormulaRegulationReferenceFormulaIdtRequest,
  UpdateFormulaRegulationStatementCaseRequest,
  UpdateFormulaRegulationSubIngredientStatementCaseRequest,
  UpdateFormulaServingWeightRequest,
  UpdateFormulaStatusRequest
} from './FormulatorRequest'
import { FormulaLabelStatementCaseType } from 'models/FormulaLabel'

export interface FormulatorState {
  formula: Formula
  loading: boolean
  saving: boolean
  formulaRegulationsSettingsEdits: FormulaRegulationSettingsEdits[]
  formulaNutrients?: FormulaNutrient[][]
  scale: FormulaNutrientsScale
}

const emptyFormula: Formula = {
  id: '',
  name: 'untitled',
  status: FormulaStatus.PRIVATE_DRAFT,
  createdAt: '2021-01-01T00:00:00.000Z',
  updatedAt: '2021-01-01T00:00:00.000Z',
  createdBy: {
    id: '1',
    firstName: 'Alice',
    lastName: 'Smith',
    fullName: '',
    imagePath: ''
  },
  imagePath: 'https://www.svgrepo.com/show/84264/recipes.svg',
  note: '',
  isArchived: false,
  cost: {
    totalCostPerServing: null,
    totalCostPerWeight: null
  },
  formulaRegulations: []
}

const initialState: FormulatorState = {
  formula: emptyFormula,
  scale: FormulaNutrientsScale.GRAMS_100,
  formulaRegulationsSettingsEdits: [{
    regulationId: '',
    statementCase: FormulaLabelStatementCaseType.NOT_SET,
    subIngredientStatementCase: FormulaLabelStatementCaseType.NOT_SET
  }],
  loading: false,
  saving: false,
  formulaNutrients: undefined
}

export const toScaleNumber = (
  scale?: FormulaNutrientsScale,
  servingWeight?: number
): number | undefined => {
  switch (scale) {
    case FormulaNutrientsScale.GRAMS_100:
      return 100
    case FormulaNutrientsScale.SERVING_SIZE:
      return servingWeight
  }

  return undefined
}

export const downloadFormulaNutrientsAsCSV = createAsyncThunk(
  'formulas/nutrients/download',
  async (
    { companyId, formulaId, scale }: DownloadFormulaNutrientsCSVRequest,
    { getState }
  ) => {
    const state = getState() as RootState
    const servingWeight = state.formulator.formula.servingWeight
    const scaleNumber = toScaleNumber(scale, servingWeight)
    return await FormulaApi.downloadFormulaNutrientsCSV(companyId, formulaId, {
      scale: scaleNumber
    })
  }
)

export const updateFormulaName = createAsyncThunk(
  'formulas/name/update',
  async ({ companyId, formulaId, name }: UpdateFormulaNameRequest) => {
    return await FormulaApi.updateFormula(companyId, formulaId, { name })
  }
)

export const updateFormulaFriendlyId = createAsyncThunk(
  'formulas/friendlyId/update',
  async ({
    companyId,
    formulaId,
    friendlyId
  }: UpdateFormulaFriendlyIdRequest) => {
    return await FormulaApi.updateFormula(companyId, formulaId, { friendlyId })
  }
)

export const updateFormulaArchive = createAsyncThunk(
  'formulas/archive/update',
  async ({ companyId, formulaId, archive }: UpdateFormulaArchiveRequest) => {
    return await FormulaApi.updateFormula(companyId, formulaId, {
      isArchived: archive
    })
  }
)

export const updateFormulaNote = createAsyncThunk(
  'formulas/note/update',
  async ({ companyId, formulaId, note }: UpdateFormulaNoteRequest) => {
    return await FormulaApi.updateFormula(companyId, formulaId, { note })
  }
)

export const updateFormulaStatus = createAsyncThunk(
  'formulas/status/update',
  async ({ companyId, formulaId, status }: UpdateFormulaStatusRequest) => {
    return await FormulaApi.updateFormula(companyId, formulaId, {
      status: fromFormulaStatus(status)
    })
  }
)

export const updateFormulaServingWeight = createAsyncThunk(
  'formulas/servingWeight/update',
  async ({
    companyId,
    formulaId,
    servingWeight
  }: UpdateFormulaServingWeightRequest) => {
    return await FormulaApi.updateFormula(companyId, formulaId, {
      servingWeight
    })
  }
)
export const addFormulaRegulationReferenceFormula = createAsyncThunk(
  'formulas/formulaRegulation/add/referenceFormula',
  async ({
    companyId,
    formulaId,
    regulationId,
    referenceFormulaId
  }: UpdateFormulaRegulationReferenceFormulaIdtRequest) => {
    return await FormulaApi.addFormulaRegulation(
      companyId,
      formulaId,
      regulationId,
      {
        referenceFormulaId
      }
    )
  }
)
export const addFormulaRegulationIsMealOrMainDish = createAsyncThunk(
  'formulas/formulaRegulation/add/isMealOrMainDish',
  async ({
    companyId,
    formulaId,
    regulationId,
    isMealOrMainDish
  }: UpdateFormulaRegulationIsMealOrMainDishRequest) => {
    return await FormulaApi.addFormulaRegulation(
      companyId,
      formulaId,
      regulationId,
      {
        isMealOrMainDish
      }
    )
  }
)
export const addFormulaRegulationRacc = createAsyncThunk(
  'formulas/formulaRegulation/add/racc',
  async ({
    companyId,
    formulaId,
    regulationId,
    racc
  }: UpdateFormulaRegulationRaccRequest) => {
    return await FormulaApi.addFormulaRegulation(
      companyId,
      formulaId,
      regulationId,
      {
        racc
      }
    )
  }
)

export const addFormulaRegulationStatementCase = createAsyncThunk(
  'formulas/formulaRegulation/add/statementCase',
  async ({
    companyId,
    formulaId,
    regulationId,
    statementCase
  }: UpdateFormulaRegulationStatementCaseRequest) => {
    return await FormulaApi.addFormulaRegulation(
      companyId,
      formulaId,
      regulationId,
      {
        statementCase: fromFormulaLabelStatementCaseType(statementCase)
      }
    )
  }
)

export const addFormulaRegulationSubIngredientStatementCase = createAsyncThunk(
  'formulas/formulaRegulation/add/subIngredientStatementCase',
  async ({
    companyId,
    formulaId,
    regulationId,
    subIngredientStatementCase
  }: UpdateFormulaRegulationSubIngredientStatementCaseRequest) => {
    return await FormulaApi.addFormulaRegulation(
      companyId,
      formulaId,
      regulationId,
      {
        subIngredientStatementCase: fromFormulaLabelStatementCaseType(
          subIngredientStatementCase
        )
      }
    )
  }
)

export const deleteFormula = createAsyncThunk(
  'formulas/delete',
  async ({ companyId, formulaId }: DeleteFormulaRequest) => {
    return await FormulaApi.deleteFormula(companyId, formulaId)
  }
)

export const duplicateFormula = createAsyncThunk(
  'formulas/duplicate',
  async ({
    companyId,
    formulaId,
    formulaIngredientIds = []
  }: DuplicateFormulaRequest) => {
    return await FormulaApi.duplicateFormula(companyId, formulaId, {
      formulaIngredientIds
    })
  }
)

export const getFormula = createAsyncThunk(
  'formulas/get',
  async ({
    companyId,
    formulaId
  }: {
    companyId: string
    formulaId: string
  }) => {
    return await FormulaApi.getFormula(companyId, formulaId)
  }
)

export const getFormulaTotalCost = createAsyncThunk(
  'formulas/totalCost',
  async ({
    companyId,
    formulaId
  }: {
    companyId: string
    formulaId: string
  }) => {
    return await FormulaApi.getFormulaTotalCost(companyId, formulaId)
  }
)

export const getFormulaNutrients = createAsyncThunk(
  'formulas/nutrients',
  async (
    {
      companyId,
      formulaId,
      scale = FormulaNutrientsScale.GRAMS_100
    }: GetFormulaNutrientRequest,
    { getState }
  ) => {
    const state = getState() as RootState
    const servingWeight = state.formulator.formula.servingWeight

    const scaleNumber = toScaleNumber(scale, servingWeight)

    return await FormulaApi.getFormulaNutrients(companyId, formulaId, {
      scale: scaleNumber
    })
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true
      }
    }
  }
)

const formulatorSlice = createSlice({
  name: 'formulatorSlice',
  initialState,
  reducers: {
    setScale: (state, action: PayloadAction<FormulaNutrientsScale>) => {
      state.scale = action.payload
    },
    resetState: (state) => {
      Object.assign(state, initialState)
    },
    setFormulaRegulationsStatementCaseEdits: (
      state,
      action: PayloadAction<{
        regulationId: string
        statementCase: FormulaLabelStatementCaseType
      }>) => {
      const regulationIndex = state.formulaRegulationsSettingsEdits.findIndex(
        (regulation) => regulation.regulationId === action.payload.regulationId
      )
      if (regulationIndex > -1) {
        state.formulaRegulationsSettingsEdits[regulationIndex].statementCase =
          action.payload.statementCase
      } else {
        state.formulaRegulationsSettingsEdits.push({
          regulationId: action.payload.regulationId,
          statementCase: action.payload.statementCase,
          subIngredientStatementCase: FormulaLabelStatementCaseType.NOT_SET
        })
      }
    },
    setFormulaRegulationsSubIngredientStatementCaseEdits: (
      state,
      action: PayloadAction<{
        regulationId: string
        subIngredientStatementCase: FormulaLabelStatementCaseType
      }>) => {
      const regulationIndex = state.formulaRegulationsSettingsEdits.findIndex(
        (regulation) => regulation.regulationId === action.payload.regulationId
      )
      if (regulationIndex > -1) {
        state.formulaRegulationsSettingsEdits[regulationIndex].subIngredientStatementCase =
          action.payload.subIngredientStatementCase
      } else {
        state.formulaRegulationsSettingsEdits.push({
          regulationId: action.payload.regulationId,
          statementCase: FormulaLabelStatementCaseType.NOT_SET,
          subIngredientStatementCase: action.payload.subIngredientStatementCase
        })
      }
    },
    resetFormulaRegulationsSettingsEdits: (state) => {
      state.formulaRegulationsSettingsEdits = state.formula.formulaRegulations.map(
        (regulation) => ({
          regulationId: regulation.regulationId,
          statementCase: regulation.statementCase,
          subIngredientStatementCase: regulation.subIngredientStatementCase
        })
      )
    }
  },
  extraReducers(builder) {
    // get formula
    builder.addCase(getFormula.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFormula.fulfilled, (state, action) => {
      state.formula = action.payload
      state.formulaRegulationsSettingsEdits = action.payload.formulaRegulations.map(
        (regulation) => ({
          regulationId: regulation.regulationId,
          statementCase: regulation.statementCase,
          subIngredientStatementCase: regulation.subIngredientStatementCase
        })
      )
      state.loading = false
    })
    builder.addCase(getFormula.rejected, (state) => {
      state.loading = false
    })

    // get formula total cost
    builder.addCase(getFormulaTotalCost.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFormulaTotalCost.fulfilled, (state, action) => {
      state.formula.cost = action.payload
      state.loading = false
    })
    builder.addCase(getFormulaTotalCost.rejected, (state) => {
      state.loading = false
    })

    // save formula name
    builder.addCase(updateFormulaName.pending, (state) => {
      state.saving = true
    })
    builder.addCase(updateFormulaName.fulfilled, (state, action) => {
      state.formula.name = action.payload.name
      state.saving = false
    })
    builder.addCase(updateFormulaName.rejected, (state) => {
      state.saving = false
    })

    // delete formula
    builder.addCase(deleteFormula.pending, (state) => {
      state.saving = true
    })
    builder.addCase(deleteFormula.fulfilled, (state) => {
      state.saving = false
    })
    builder.addCase(deleteFormula.rejected, (state) => {
      state.saving = false
    })

    // duplicate formula
    builder.addCase(duplicateFormula.pending, (state) => {
      state.saving = true
    })
    builder.addCase(duplicateFormula.fulfilled, (state, action) => {
      state.formula = {
        ...action.payload
      }
      state.saving = false
    })
    builder.addCase(duplicateFormula.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaNote
    builder.addCase(updateFormulaNote.pending, (state) => {
      state.saving = true
    })
    builder.addCase(updateFormulaNote.fulfilled, (state) => {
      state.saving = false
    })
    builder.addCase(updateFormulaNote.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaServingWeight
    builder.addCase(updateFormulaServingWeight.pending, (state) => {
      state.saving = true
    })
    builder.addCase(updateFormulaServingWeight.fulfilled, (state, action) => {
      state.formula.servingWeight = action.payload.servingWeight
      state.saving = false
    })
    builder.addCase(updateFormulaServingWeight.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaStatus
    builder.addCase(updateFormulaStatus.pending, (state) => {
      state.saving = true
    })
    builder.addCase(updateFormulaStatus.fulfilled, (state, action) => {
      state.formula.status = action.payload.status
      state.saving = false
    })
    builder.addCase(updateFormulaStatus.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaArchive
    builder.addCase(updateFormulaArchive.pending, (state) => {
      state.saving = true
    })
    builder.addCase(updateFormulaArchive.fulfilled, (state, action) => {
      state.formula.isArchived = action.payload.isArchived
      state.saving = false
    })
    builder.addCase(updateFormulaArchive.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaRegulationReferenceFormula
    builder.addCase(addFormulaRegulationReferenceFormula.pending, (state) => {
      state.saving = true
    })
    builder.addCase(
      addFormulaRegulationReferenceFormula.fulfilled,
      (state, action) => {
        const currentRegulationsIds = state.formula.formulaRegulations.map(
          (regulation) => regulation.regulationId
        )

        const updatedFormulaRegulations = state.formula.formulaRegulations.map(
          (regulation) => {
            if (regulation.regulationId === action.payload.regulationId) {
              return {
                ...regulation,
                referenceFormula: action.payload.referenceFormula
              }
            }
            return regulation
          }
        )
        if (!currentRegulationsIds.includes(action.payload.regulationId)) {
          updatedFormulaRegulations.push(action.payload)
        }
        state.formula.formulaRegulations = [...updatedFormulaRegulations]
        state.saving = false
      }
    )
    builder.addCase(addFormulaRegulationReferenceFormula.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaRegulationIsMealOrMainDish
    builder.addCase(addFormulaRegulationIsMealOrMainDish.pending, (state) => {
      state.saving = true
    })
    builder.addCase(
      addFormulaRegulationIsMealOrMainDish.fulfilled,
      (state, action) => {
        const currentRegulationsIds = state.formula.formulaRegulations.map(
          (regulation) => regulation.regulationId
        )

        const updatedFormulaRegulations = state.formula.formulaRegulations.map(
          (regulation) => {
            if (regulation.regulationId === action.payload.regulationId) {
              return {
                ...regulation,
                isMealOrMainDish: action.payload.isMealOrMainDish
              }
            }
            return regulation
          }
        )
        if (!currentRegulationsIds.includes(action.payload.regulationId)) {
          updatedFormulaRegulations.push(action.payload)
        }
        state.formula.formulaRegulations = [...updatedFormulaRegulations]
        state.saving = false
      }
    )
    builder.addCase(addFormulaRegulationIsMealOrMainDish.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaRegulationRacc
    builder.addCase(addFormulaRegulationRacc.pending, (state) => {
      state.saving = true
    })
    builder.addCase(addFormulaRegulationRacc.fulfilled, (state, action) => {
      const currentRegulationsIds = state.formula.formulaRegulations.map(
        (regulation) => regulation.regulationId
      )

      const updatedFormulaRegulations = state.formula.formulaRegulations.map(
        (regulation) => {
          if (regulation.regulationId === action.payload.regulationId) {
            return {
              ...regulation,
              racc: action.payload.racc
            }
          }
          return regulation
        }
      )
      if (!currentRegulationsIds.includes(action.payload.regulationId)) {
        updatedFormulaRegulations.push(action.payload)
      }
      state.formula.formulaRegulations = [...updatedFormulaRegulations]
      state.saving = false
    })
    builder.addCase(addFormulaRegulationRacc.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaRegulationStatementCase
    builder.addCase(addFormulaRegulationStatementCase.pending, (state) => {
      state.saving = true
    })
    builder.addCase(
      addFormulaRegulationStatementCase.fulfilled,
      (state, action) => {
        const currentRegulationsIds = state.formula.formulaRegulations.map(
          (regulation) => regulation.regulationId
        )

        const updatedFormulaRegulations = state.formula.formulaRegulations.map(
          (regulation) => {
            if (regulation.regulationId === action.payload.regulationId) {
              return {
                ...regulation,
                statementCase: action.payload.statementCase
              }
            }
            return regulation
          }
        )
        if (!currentRegulationsIds.includes(action.payload.regulationId)) {
          updatedFormulaRegulations.push(action.payload)
        }
        state.formula.formulaRegulations = [...updatedFormulaRegulations]
        state.saving = false
      }
    )
    builder.addCase(addFormulaRegulationStatementCase.rejected, (state) => {
      state.saving = false
    })

    // updateFormulaRegulationSubIngredientStatementCase
    builder.addCase(
      addFormulaRegulationSubIngredientStatementCase.pending,
      (state) => {
        state.saving = true
      }
    )
    builder.addCase(
      addFormulaRegulationSubIngredientStatementCase.fulfilled,
      (state, action) => {
        const currentRegulationsIds = state.formula.formulaRegulations.map(
          (regulation) => regulation.regulationId
        )

        const updatedFormulaRegulations = state.formula.formulaRegulations.map(
          (regulation) => {
            if (regulation.regulationId === action.payload.regulationId) {
              return {
                ...regulation,
                subIngredientStatementCase:
                  action.payload.subIngredientStatementCase
              }
            }
            return regulation
          }
        )
        if (!currentRegulationsIds.includes(action.payload.regulationId)) {
          updatedFormulaRegulations.push(action.payload)
        }
        state.formula.formulaRegulations = [...updatedFormulaRegulations]
        state.saving = false
      }
    )
    builder.addCase(
      addFormulaRegulationSubIngredientStatementCase.rejected,
      (state) => {
        state.saving = false
      }
    )

    // getFormulaNutrient
    builder.addCase(getFormulaNutrients.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFormulaNutrients.fulfilled, (state, action) => {
      state.formulaNutrients = action.payload
      state.loading = false
    })
    builder.addCase(getFormulaNutrients.rejected, (state) => {
      state.loading = false
      state.formulaNutrients = []
    })
  }
})

export const {
  setScale,
  resetState,
  setFormulaRegulationsStatementCaseEdits,
  setFormulaRegulationsSubIngredientStatementCaseEdits,
  resetFormulaRegulationsSettingsEdits
} = formulatorSlice.actions
export default formulatorSlice.reducer
