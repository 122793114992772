import React from 'react'
import { pluralize } from 'common/utils'
import {
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import { NutritionFactsProps } from '../NutritionLabel'
import { NutritionFactsRule } from '../components/NutritionFactsRule'
import {
  Container,
  FlexContainerRowSpaced,
  ServingContainer
} from '../components/NutritionLabelStyles'
import { NutritionLabelText } from '../components/NutritionLabelText'
import { NutritionLabelDualBox } from './NutritionLabelDualStyles'
import { NutrientsTable } from './components/NutrientsTable'
import { FootNoteContainer } from '../NutritionLabelVertical/NutritionLabelVerticalStyles'

export const NutritionLabelDual: React.FC<NutritionFactsProps> = ({
  servingsPerContainer,
  servingSize,
  servingWeight,
  topNutrients,
  bottomNutrients,
  calories,
  ageGroup,
  perContainer
}) => {
  return (
    <>
      {perContainer && (
        <Container>
          <NutritionLabelDualBox>
            <NutritionLabelText bold points={20}>
              Nutrition Facts
            </NutritionLabelText>
            <NutritionFactsRule type="hairline" margin={2} />
            <ServingContainer>
              <NutritionLabelText points={10}>
                {servingsPerContainer}{' '}
                {pluralize(servingsPerContainer, 'serving')} per container
              </NutritionLabelText>
              <FlexContainerRowSpaced>
                <NutritionLabelText bold points={10}>
                  Serving size&nbsp;
                  {/* Added empty space to keep one character between long serving size and Serving Size */}
                </NutritionLabelText>
                {Object.keys(servingSize).map((language) => (
                  <NutritionLabelText bold points={10} key={language}>
                    {servingSize[language as FormulaNutritionFactLanguage]} (
                    {servingWeight})
                  </NutritionLabelText>
                ))}
              </FlexContainerRowSpaced>
            </ServingContainer>
            <NutritionFactsRule type="thick" margin={2} />
            <NutrientsTable
              perContainerCalories={perContainer.calories || '0'}
              perContainerTopNutrients={perContainer.topNutrients}
              perContainerBottomNutrients={perContainer.bottomNutrients}
              perServingCalories={calories || '0'}
              perServingTopNutrients={topNutrients}
              perServingBottomNutrients={bottomNutrients}
            />
            {ageGroup != FormulaNutritionFactLabelAgeGroup.INFANT && (
              <>
                <NutritionFactsRule type="normal" margin={2} />

                <FootNoteContainer>
                  <NutritionLabelText points={6}>*&nbsp; </NutritionLabelText>
                  <NutritionLabelText points={6} wrapText={true}>
                    The % Daily Value (DV) tells you how much a nutrient in a
                    serving of food contributes to a daily diet.{' '}
                    {ageGroup ===
                    FormulaNutritionFactLabelAgeGroup.CHILDREN_LESS_THAN_FOUR
                      ? '1,000'
                      : '2,000'}{' '}
                    calories a day is used for general nutrition advice.
                  </NutritionLabelText>
                </FootNoteContainer>
              </>
            )}
          </NutritionLabelDualBox>
        </Container>
      )}
    </>
  )
}
