import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  clearNutritionFactLabelEdits,
  setNutritionFactLabelEdits
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { AllergensContainer } from './components/Allergens/AllergensContainer'
import { GeneralContainer } from './components/General/GeneralContainer'
import { EditorPanel } from './EditorPanel'
import { NutrientsOverridesContainer } from './components/Overrides/components/Nutrients/NutrientsOverridesContainer'
import { OverridesContainer } from './components/Overrides/OverridesContainer'
import { resetFormulaRegulationsSettingsEdits } from 'state/formulator/FormulatorSlice'

export interface EditorPanelContainerProps {
  visible: boolean
}

export const EditorPanelContainer: React.FC<EditorPanelContainerProps> = ({
  visible
}) => {
  const dispatch = useAppDispatch()
  const nutritionFactLabel = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel
  )
  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )
  const formulaAllergens = useAppSelector(
    (state) => state.formulatorAllergens.formulaAllergens
  )

  React.useEffect(() => {
    // This should only happen when the panel is visible to reset the edits.
    if (nutritionFactLabel && visible) {
      dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          nutritionFactLabelPreview: nutritionFactLabel,
          allergens: formulaAllergens.allergens.map((a) => a.type)
        })
      )
    }
  }, [visible])

  React.useEffect(() => {
    if (!visible) {
      dispatch(clearNutritionFactLabelEdits())
      dispatch(resetFormulaRegulationsSettingsEdits())
    }
  }, [visible])

  return (
    <EditorPanel
      general={<GeneralContainer />}
      allergens={<AllergensContainer />}
      overrides={<OverridesContainer />}
    />
  )
}
