import { Option } from 'components/common'
import React from 'react'
import { EditorSection } from '../common/EditorSection'
import { AllergenSwitch } from './AllergenSwitch'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'
import { TextField } from 'components/TextField/TextField'
import { pluralize } from 'common/utils'

export interface AllergenOption extends Option<boolean> {
  ingredientsCount: number
  description?: string
}

interface AllergensProps {
  allergens: AllergenOption[]
  onChange: (id: string, checked: boolean) => void
  allergenStatements: {
    language: FormulaNutritionFactLanguage
    value: string
    suggestedValue: string
    onChange: (value: string) => void
  }[]
}

export const Allergens: React.FC<AllergensProps> = ({
  allergens,
  onChange,
  allergenStatements
}) => {
  return (
    <>
      <EditorSection
        title="Allergens"
        withSeparator={true}
        content={allergens.map((allergen) => (
          <AllergenSwitch
            key={allergen.id}
            label={allergen.label}
            checked={allergen.value}
            ingredientsCount={allergen.ingredientsCount}
            onChange={(c) => onChange(allergen.id, c)}
            description={allergen.description}
          />
        ))}
      />
      <EditorSection
        title={pluralize(allergenStatements.length, 'Statement')}
        withSeparator={false}
        content={allergenStatements
          .sort((a, b) => a.language.localeCompare(b.language))
          .map((allergenStatement, index) => (
            <TextField
              key={index}
              label={`Allergen Statement ${
                allergenStatement.language !==
                FormulaNutritionFactLanguage.ENGLISH
                  ? `(${allergenStatement.language})`
                  : ''
              }`}
              type="text"
              value={
                allergenStatement.value || allergenStatement.suggestedValue
              }
              onChange={(value) => allergenStatement.onChange(value)}
              rows={3}
              multiline
            />
          ))}
      />
    </>
  )
}
