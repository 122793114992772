import { useQuery } from 'common/hooks'
import { ModalContext } from 'components/Modal/ModalContext'
import { SelectOption } from 'components/SelectOptionsLabel/SelectOptionsLabel'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { DetailedSimpleIngredient } from 'models/SimpleIngredient'
import React from 'react'
import { getAllergens } from 'state/allergens/AllergensSlice'
import { getSupplierFilters, getTagsFilters } from 'state/filters/FiltersSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  duplicateSimpleIngredient,
  getSimpleIngredient,
  getSimpleIngredients,
  getSupplierOptions,
  setSelectedSimpleIngredient
} from 'state/simple_ingredients/SimpleIngredientsSlice'
import { getCompanyTags } from 'state/tags/TagsSlice'
import { IngredientViewer } from './IngredientViewer'
import { PageViewerContext } from '../PageViewerContext'
import { IngredientType } from 'models/Ingredient'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'

// TODO: This is a temporary container and should be refactored.
// It was made as a quick wrapper to avoid a major refactor of the viewer.

export const IngredientsViewerContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const { showConfirmationModal } = React.useContext(ModalContext)
  const { request, viewedItemId, clearRequest, setViewedItemId } =
    React.useContext(PageViewerContext)

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const { items, page, pages } = useAppSelector(
    (state) => state.simpleIngredients.simpleIngredients
  )
  const deleting = useAppSelector((state) => state.simpleIngredients.deleting)
  const allergenSelectionOptions = useAppSelector(
    (state) => state.allergens.allergens
  )
  const supplierSelectionOptions = useAppSelector(
    (state) => state.simpleIngredients.supplierOptions
  )
  const tagSelectionOptions = useAppSelector((state) => state.tags.tags)

  const selectedIngredient = useAppSelector(
    (state) => state.simpleIngredients.selectedSimpleIngredient
  )

  const { ingredientsAnalytics } = React.useContext(AnalyticsContext)

  const [viewerVisible, setViewerVisible] = React.useState(false)
  const [newIngredient, setNewIngredient] = React.useState(false)

  // When viewed item id changes, get the ingredient information and set is as selected.
  React.useEffect(() => {
    if (viewedItemId) {
      void dispatch(
        getSimpleIngredient({
          companyId: currentCompany.id,
          simpleIngredientId: viewedItemId
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(setSelectedSimpleIngredient(res))
        })
    }
  }, [viewedItemId])

  const { showError, showSuccess } = React.useContext(SnackbarContext)

  const allergenOptions: SelectOption[] = allergenSelectionOptions.map((a) => ({
    label: a.name,
    value: a.type
  }))

  const supplierOptions: SelectOption[] = supplierSelectionOptions.map((s) => ({
    value: s.id,
    label: s.name
  }))

  const tagOptions: SelectOption[] = tagSelectionOptions.map((a) => ({
    value: a.id,
    label: a.name
  }))

  // Show the viewer.
  const showViewer = () => {
    setViewerVisible(true)
  }

  // Hides the viewer and runs all the necessary conditions.
  const hideViewer = () => {
    if (newIngredient && selectedIngredient?.id && !deleting) {
      showAllergenNotProvidedModal(() => {
        setViewerVisible(false)
        setNewIngredient(false)
      })
    } else {
      setViewerVisible(false)
    }
  }

  const handleNewIngredient = () => {
    const newSimpleIngredient: DetailedSimpleIngredient = {
      id: '',
      name: '',
      friendlyId: '',
      company: currentCompany,
      createdAt: '',
      updatedAt: '',
      verified: false,
      ingredientStatement: '',
      manufacturer: '',
      formulas: [],
      suppliers: [],
      tags: [],
      costs: [],
      type: IngredientType.SIMPLE,
      isSugar: false
    }
    dispatch(setSelectedSimpleIngredient(newSimpleIngredient))
    setNewIngredient(true)
    setViewerVisible(true)
  }

  const handleShowRequest = (id?: string) => {
    // Hide the viewer if it's the same selected ingredient
    if (selectedIngredient && selectedIngredient.id === id) {
      hideViewer()
    } else if (newIngredient && selectedIngredient?.id) {
      // If we're dismissing the current ingredient which is a new ingredient, show the modal before accepting the request.
      showAllergenNotProvidedModal(() => {
        setNewIngredient(false)
        setViewedItemId(id)
      })
    } else if (id === undefined) {
      // We need to create a new ingredient.
      handleNewIngredient()
      setViewedItemId(undefined)
    } else {
      // Switch to the newly selected ingredient.
      setNewIngredient(false)
      setViewedItemId(id)
    }
    clearRequest()
  }

  const handleHideRequest = React.useCallback(() => {
    hideViewer()
  }, [hideViewer])

  React.useEffect(() => {
    if (request) {
      if (request.type === 'show') {
        handleShowRequest(request.id)
      } else {
        handleHideRequest()
      }
    }
  }, [request])

  /* open viewer when ingredient is selected */
  React.useEffect(() => {
    if (selectedIngredient) {
      showViewer()
    }
  }, [selectedIngredient])

  /* set selected ingredient to undefined when viewer closes */
  React.useEffect(() => {
    if (!viewerVisible && selectedIngredient) {
      setNewIngredient(false)
      dispatch(setSelectedSimpleIngredient(undefined))
      setViewedItemId(undefined)
    }
  }, [viewerVisible])

  /* auto close viewer on delete */
  React.useEffect(() => {
    if (deleting) {
      hideViewer()
    }
  }, [deleting])

  const showAllergenNotProvidedModal = (onYesClicked: () => void) => {
    showConfirmationModal({
      title: 'Allergen Tags',
      message: (
        <>
          Are you sure that all relevant allergens are tagged for this
          ingredient?
        </>
      ),
      yesText: 'Yes',
      noText: 'No',
      onYesClicked: () => onYesClicked()
    })
  }

  const fetchSimpleIngredients = () => {
    void dispatch(getSimpleIngredients({ companyId: currentCompany.id }))
      .unwrap()
      .catch(() => {
        showError('Failed to load ingredients.')
      })
  }

  const onIngredientUpdate = (
    ingredient?: DetailedSimpleIngredient,
    refresh?: boolean
  ) => {
    // update ingredient from api response
    if (ingredient) dispatch(setSelectedSimpleIngredient({ ...ingredient }))
    // refresh ingredients
    if (refresh) fetchSimpleIngredients()
  }

  React.useEffect(() => {
    // get company tags
    void dispatch(
      getCompanyTags({
        companyId: currentCompany.id
      })
    )
      .unwrap()
      .catch(() => {
        showError('Failed to load company tags')
      })
    // get suppliers
    void dispatch(
      getSupplierOptions({
        companyId: currentCompany.id,
        withPublicSuppliers: false
      })
    )
      .unwrap()
      .catch(() => {
        showError('Failed to load suppliers.')
      })
    // get available options.
    void dispatch(getSupplierFilters({ companyId: currentCompany.id }))
    void dispatch(getTagsFilters({ companyId: currentCompany.id }))
  }, [currentCompany])

  const duplicateSelectedIngredient = () => {
    if (selectedIngredient) {
      ingredientsAnalytics.duplicatedIngredient(selectedIngredient.id)
      void dispatch(
        duplicateSimpleIngredient({
          companyId: currentCompany.id,
          simpleIngredientId: selectedIngredient.id,
          simpleIngredientCompanyId: selectedIngredient.company.id
        })
      )
        .unwrap()
        .catch(() => {
          showError(
            `Failed to duplicate the ingredient ${selectedIngredient.name}`
          )
        })
    }
  }

  React.useEffect(() => {
    if (!allergenSelectionOptions.length) {
      void dispatch(getAllergens())
        .unwrap()
        .catch(() => {
          showError('Failed to load allergens')
        })
    }
  }, [allergenSelectionOptions])

  React.useEffect(() => {
    if (selectedIngredient) {
      const updatedIngredient = items.find(
        (s) => s.id === selectedIngredient.id
      )
      if (updatedIngredient) {
        const newData = JSON.stringify({ ...updatedIngredient, updatedAt: '' })
        const oldData = JSON.stringify({ ...selectedIngredient, updatedAt: '' })
        if (newData !== oldData) {
          // refresh selected ingredient data after update
          dispatch(setSelectedSimpleIngredient(updatedIngredient))
        }
      }
    }
  }, [items])

  return (
    <IngredientViewer
      show={viewerVisible}
      simpleIngredient={selectedIngredient}
      allergenOptions={allergenOptions}
      supplierOptions={supplierOptions}
      tagOptions={tagOptions}
      onSimpleIngredientUpdate={onIngredientUpdate}
      onSelectedSimpleIngredientDuplicate={duplicateSelectedIngredient}
      readOnly={selectedIngredient?.company.public}
    />
  )
}
