import { Comment } from 'models/Comment'
import {
  BasicFormula,
  Formula,
  FormulaAllergen,
  FormulaAllergens,
  FormulaCost,
  FormulaNutrient,
  FormulaRegulation,
  FormulaStatus,
  FormulasPaginated
} from 'models/Formula'
import {
  FormulaCommentApiResponse,
  FormulaCommentApiPaginatedResponse,
  FormulaApiResponse,
  FormulaApiPaginatedResponse,
  FormulaApiStatus,
  FormulaIngredientApiResponse,
  BasicFormulaApiResponse,
  FormulaIngredientStatementApiResponse,
  FormulaNutrientApiResponse,
  FormulaIngredientNutrientApiResponse,
  FormulaIngredientApiPaginatedResponse,
  FormulaNutrientsApiResponse,
  FormulaNutritionFactApiResponse,
  FormulaAllergensApiResponse,
  FormulaAllergenApiResponse,
  NutritionFactLabelApiType,
  FormulaNutritionFactLabelHeaderApiResponse,
  FormulaNutritionFactsOptionalNutrientsApiResponse,
  NutritionFactNutrientApiResponse,
  NutritionFactLabelApiAgeGroup,
  FormulaTargetApiResponse,
  BasicFormulaTargetCategoryApiResponse,
  OperationExecutionErrorTypeApiResponse,
  OperationExecutionErrorApiResponse,
  OperationExecutionResultApiResponse,
  TruthSetExecutionResultApiResponse,
  FormulaNutritionFactLanguageApiResponse,
  FormulaNutritionFactLabelLangSpecificFieldsApiResponse,
  FormulaCostApiResponse,
  FormulaRegulationApiResponse,
  FormulaLabelStatementApiCaseType,
  OverrideValuesApiResponse
} from './FormulaApiResponse'
import {
  FormulaIngredient,
  FormulaIngredientNutrient
} from 'models/FormulaIngredient'
import {
  FormulaIngredientStatement,
  FormulaLabelStatementCaseType,
  FormulaNutritionFact,
  FormulaNutritionFactLabelAgeGroup,
  FormulaNutritionFactLabelHeader,
  FormulaNutritionFactLabelLangSpecificFields,
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage,
  FormulaNutritionFactNutrient,
  OverrideValues
} from 'models/FormulaLabel'
import {
  BasicFormulaTargetCategory,
  FormulaTarget,
  OperationExecutionError,
  OperationExecutionErrorType,
  OperationExecutionResult,
  TruthSetExecutionResult
} from 'models/FormulaTargets'
import { IngredientNutrient } from 'models/Ingredient'
import {
  toBasicIngredient,
  toBasicIngredientFromSimpleIngredient
} from '../ingredient/IngredientApiMapper'
import { IngredientNutrientApiResponse } from '../ingredient/IngredientApiResponse'
import { toMeasurement } from '../measurements/MeasurementApiMapper'
import { toNutrient, toNutrientType } from '../nutrient/NutrientApiMapper'
import { toBasicSupplier } from '../supplier/SupplierApiMapper'
import { toBasicTarget, toOperation } from '../target/TargetApiMapper'
import { toBasicUser } from '../user/UserApiMapper'

export const toBasicFormula = (
  response: BasicFormulaApiResponse
): BasicFormula => {
  return {
    ...response
  }
}

export const toFormulaRegulation = (
  response: FormulaRegulationApiResponse
): FormulaRegulation => {
  return {
    ...response,
    referenceFormula: response.referenceFormula
      ? toBasicFormula(response.referenceFormula)
      : undefined,
    statementCase: response.statementCase ? toFormulaLabelStatementCaseType(response.statementCase) : FormulaLabelStatementCaseType.NOT_SET,
    subIngredientStatementCase: response.subIngredientStatementCase ? toFormulaLabelStatementCaseType(response.subIngredientStatementCase) : FormulaLabelStatementCaseType.NOT_SET
  }
}

export const toFormulaRegulations = (
  response: FormulaRegulationApiResponse[]
): FormulaRegulation[] => {
  return response.map((formulaRegulation) => {
    return toFormulaRegulation(formulaRegulation)
  })
}

export const toFormula = (response: FormulaApiResponse): Formula => {
  return {
    ...response,
    status: toFormulaStatus(response.status),
    createdBy: response.createdBy ? toBasicUser(response.createdBy) : undefined,
    cost: {
      totalCostPerServing: null,
      totalCostPerWeight: null
    },
    formulaRegulations: toFormulaRegulations(response.formulaRegulations)
  }
}

export const toFormulaCost = (
  response: FormulaCostApiResponse
): FormulaCost => {
  return {
    ...response
  }
}

export const toFormulasPaginated = (
  response: FormulaApiPaginatedResponse
): FormulasPaginated => {
  return {
    ...response,
    items: response.items.map((formula) => toFormula(formula))
  }
}

export const toFormulaLabelStatementCaseType = (
  statementCase: FormulaLabelStatementApiCaseType
): FormulaLabelStatementCaseType => {
  switch (statementCase) {
    case FormulaLabelStatementApiCaseType.ALL_CAPS:
      return FormulaLabelStatementCaseType.ALL_CAPS
    case FormulaLabelStatementApiCaseType.ALL_LOWERCASE:
      return FormulaLabelStatementCaseType.ALL_LOWERCASE
    case FormulaLabelStatementApiCaseType.TITLE_CASE:
      return FormulaLabelStatementCaseType.TITLE_CASE
    case FormulaLabelStatementApiCaseType.SENTENCE_CASE:
      return FormulaLabelStatementCaseType.SENTENCE_CASE
    case FormulaLabelStatementApiCaseType.NOT_SET:
      return FormulaLabelStatementCaseType.NOT_SET
  }
}

export const fromFormulaLabelStatementCaseType = (
  statementCase: FormulaLabelStatementCaseType
): FormulaLabelStatementApiCaseType => {
  switch (statementCase) {
    case FormulaLabelStatementCaseType.ALL_CAPS:
      return FormulaLabelStatementApiCaseType.ALL_CAPS
    case FormulaLabelStatementCaseType.ALL_LOWERCASE:
      return FormulaLabelStatementApiCaseType.ALL_LOWERCASE
    case FormulaLabelStatementCaseType.TITLE_CASE:
      return FormulaLabelStatementApiCaseType.TITLE_CASE
    case FormulaLabelStatementCaseType.SENTENCE_CASE:
      return FormulaLabelStatementApiCaseType.SENTENCE_CASE
    case FormulaLabelStatementCaseType.NOT_SET:
      return FormulaLabelStatementApiCaseType.NOT_SET
  }
}

export const fromFormulaNutritionFactLanguage = (
  Language: FormulaNutritionFactLanguage
): FormulaNutritionFactLanguageApiResponse => {
  switch (Language) {
    case FormulaNutritionFactLanguage.ENGLISH:
      return FormulaNutritionFactLanguageApiResponse.ENGLISH
    case FormulaNutritionFactLanguage.FRENCH:
      return FormulaNutritionFactLanguageApiResponse.FRENCH
    case FormulaNutritionFactLanguage.SPANISH:
      return FormulaNutritionFactLanguageApiResponse.SPANISH
  }
}

export const toFormulaNutritionFactLanguage = (
  Language: FormulaNutritionFactLanguageApiResponse
): FormulaNutritionFactLanguage => {
  switch (Language) {
    case FormulaNutritionFactLanguageApiResponse.ENGLISH:
      return FormulaNutritionFactLanguage.ENGLISH
    case FormulaNutritionFactLanguageApiResponse.FRENCH:
      return FormulaNutritionFactLanguage.FRENCH
    case FormulaNutritionFactLanguageApiResponse.SPANISH:
      return FormulaNutritionFactLanguage.SPANISH
  }
}

export const toFormulaStatus = (status: FormulaApiStatus): FormulaStatus => {
  switch (status) {
    case FormulaApiStatus.IN_REVIEW:
      return FormulaStatus.IN_REVIEW
    case FormulaApiStatus.PRIVATE_DRAFT:
      return FormulaStatus.PRIVATE_DRAFT
    case FormulaApiStatus.APPROVED:
      return FormulaStatus.APPROVED
    case FormulaApiStatus.IN_MARKET:
      return FormulaStatus.IN_MARKET
    default:
      return FormulaStatus.PRIVATE_DRAFT
  }
}

export const fromFormulaStatus = (
  status: FormulaStatus | undefined
): FormulaApiStatus => {
  switch (status) {
    case FormulaStatus.IN_REVIEW:
      return FormulaApiStatus.IN_REVIEW
    case FormulaStatus.PRIVATE_DRAFT:
      return FormulaApiStatus.PRIVATE_DRAFT
    case FormulaStatus.APPROVED:
      return FormulaApiStatus.APPROVED
    case FormulaStatus.IN_MARKET:
      return FormulaApiStatus.IN_MARKET
    default:
      return FormulaApiStatus.PRIVATE_DRAFT
  }
}

export const toComment = (response: FormulaCommentApiResponse): Comment => {
  return {
    ...response,
    createdBy: toBasicUser(response.createdBy)
  }
}

export const toComments = (
  response: FormulaCommentApiPaginatedResponse
): Comment[] => {
  return response.items.map((comment) => toComment(comment))
}

export const toFormulaIngredient = (
  response: FormulaIngredientApiResponse
): FormulaIngredient => {
  return {
    ...response,
    ingredient: toBasicIngredientFromSimpleIngredient(response.ingredient),
    supplier: toBasicSupplier(response.supplier),
    formula: toBasicFormula(response.formula),
    measurement: toMeasurement(response.measurement),
    amountInPercentage: 0
  }
}

export const toFormulaIngredients = (
  response: FormulaIngredientApiPaginatedResponse
): FormulaIngredient[] => {
  return response.items.map((formulaIngredientApiResponse) =>
    toFormulaIngredient(formulaIngredientApiResponse)
  )
}

export const toFormulaIngredientStatement = (
  response: FormulaIngredientStatementApiResponse
): FormulaIngredientStatement => {
  return {
    ...response,
    suggestedIngredientStatement: response.suggestedIngredientStatement
      ? mapLangSpecificFields(response.suggestedIngredientStatement)
      : undefined,
    ingredientStatementPrefix: response.ingredientStatementPrefix
      ? mapLangSpecificFields(response.ingredientStatementPrefix)
      : undefined
  }
}

export const toIngredientNutrient = (
  response: IngredientNutrientApiResponse
): IngredientNutrient => {
  return {
    ingredient: toBasicIngredient(response.ingredient),
    nutrient: toNutrient(response.nutrient),
    amount: response.amount,
    children: [],
    isCalculated: response.isCalculated
  }
}

export const toFormulaIngredientNutrient = (
  response: FormulaIngredientNutrientApiResponse
): FormulaIngredientNutrient => {
  return {
    ingredientNutrient: toIngredientNutrient(response.nutrient)
  }
}

export const toFormulaIngredientNutrients = (
  response: FormulaIngredientNutrientApiResponse[]
): FormulaIngredientNutrient[] => {
  return response.map((formulaIngredientNutrient) =>
    toFormulaIngredientNutrient(formulaIngredientNutrient)
  )
}

export const toFormulaNutrient = (
  response: FormulaNutrientApiResponse
): FormulaNutrient => {
  return {
    formula: toBasicFormula(response.formula),
    nutrient: toNutrient(response.nutrient),
    total: response.total,
    dvPercentage: response.dvPercentage,
    ingredients: toFormulaIngredientNutrients(response.ingredients),
    children: response.children.map((c) => toFormulaNutrient(c)),
    modifiers: response.modifiers.map((modifier) => {
      return {
        name: modifier.name,
        amount: modifier.amount,
        unit: response.nutrient.unit
      }
    })
  }
}

export const toFormulaNutrients = (
  response: FormulaNutrientsApiResponse
): FormulaNutrient[][] => {
  return response.items.map((formulaNutrientGroup) =>
    formulaNutrientGroup.map((formulaNutrient) => {
      return toFormulaNutrient(formulaNutrient)
    })
  )
}

export const toOverrideValues = (
  response: OverrideValuesApiResponse
): OverrideValues => {
  return {
    amount: response.amount,
    dv: response.dv,
    nutrientDisplayName:
      response.nutrientDisplayName
        ? mapLangSpecificFields(response.nutrientDisplayName)
        : {},
    perContainerAmount: response.perContainerAmount,
    perContainerDv: response.perContainerDv
  }
}

export const toFormulaNutritionFactNutrient = (
  response: NutritionFactNutrientApiResponse
): FormulaNutritionFactNutrient => {
  return {
    ...response,
    nutrient: toNutrient(response.nutrient),
    overrideValues:
      response.overrideValues
        ? toOverrideValues(response.overrideValues)
        : {}
  }
}

export const toFormulaNutritionFactNutrients = (
  response: NutritionFactNutrientApiResponse[]
): FormulaNutritionFactNutrient[] => {
  return response.map((formulaNutritionFactNutrient) => {
    return toFormulaNutritionFactNutrient(formulaNutritionFactNutrient)
  })
}

const mapLangSpecificFields = (
  response: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
): FormulaNutritionFactLabelLangSpecificFields => {
  return Object.entries(response).reduce((acc, [language, value]) => {
    acc[
      toFormulaNutritionFactLanguage(
        language as FormulaNutritionFactLanguageApiResponse
      )
    ] = value
    return acc
  }, {} as FormulaNutritionFactLabelLangSpecificFields)
}

export const toFormulaNutritionFact = (
  response: FormulaNutritionFactApiResponse
): FormulaNutritionFact => {
  return {
    ...response,
    nutrients: toFormulaNutritionFactNutrients(response.nutrients),
    type: toNutritionFactLabelType(response.type),
    optionalNutrientsType: response.optionalNutrients.map((optionalNutrient) =>
      toNutrientType(optionalNutrient.type)
    ),
    ageGroup: toNutritionFactLabelAgeGroup(response.ageGroup),
    servingSize: mapLangSpecificFields(response.servingSize),
    description: mapLangSpecificFields(response.description),
    ingredientStatement: mapLangSpecificFields(response.ingredientStatement),
    allergenStatement: mapLangSpecificFields(response.allergenStatement),
    suggestedAllergenStatement: mapLangSpecificFields(
      response.suggestedAllergenStatement
    ),
    allergenStatementPrefix: mapLangSpecificFields(
      response.allergenStatementPrefix
    ),
    servingWeightOverride: response.servingWeightOverride ?? ''
  }
}

export const toFormulaAllergens = (
  response: FormulaAllergensApiResponse
): FormulaAllergens => {
  return {
    ...response
  }
}

export const toFormulaAllergen = (
  response: FormulaAllergenApiResponse
): FormulaAllergen => {
  return {
    ...response
  }
}

export const toFormulaNutritionFactLabelHeader = (
  response: FormulaNutritionFactLabelHeaderApiResponse
): FormulaNutritionFactLabelHeader => {
  return {
    ...response,
    type: toNutritionFactLabelType(response.type),
    ageGroup: toNutritionFactLabelAgeGroup(response.ageGroup),
    description: mapLangSpecificFields(response.description),
    ingredientStatement: mapLangSpecificFields(response.ingredientStatement),
    allergenStatement: mapLangSpecificFields(response.allergenStatement),
    servingSize: mapLangSpecificFields(response.servingSize),
    servingWeightOverride: response.servingWeightOverride ?? ''
  }
}

export const toNutritionFactLabelType = (
  type: NutritionFactLabelApiType
): FormulaNutritionFactLabelType => {
  switch (type) {
    case NutritionFactLabelApiType.LINEAR:
      return FormulaNutritionFactLabelType.LINEAR
    case NutritionFactLabelApiType.TABULAR:
      return FormulaNutritionFactLabelType.TABULAR
    case NutritionFactLabelApiType.VERTICAL:
      return FormulaNutritionFactLabelType.VERTICAL
    case NutritionFactLabelApiType.DUAL:
      return FormulaNutritionFactLabelType.DUAL
    default:
      return FormulaNutritionFactLabelType.VERTICAL
  }
}

export const fromNutritionFactLabelType = (
  type: FormulaNutritionFactLabelType
): NutritionFactLabelApiType => {
  switch (type) {
    case FormulaNutritionFactLabelType.LINEAR:
      return NutritionFactLabelApiType.LINEAR
    case FormulaNutritionFactLabelType.TABULAR:
      return NutritionFactLabelApiType.TABULAR
    case FormulaNutritionFactLabelType.VERTICAL:
      return NutritionFactLabelApiType.VERTICAL
    case FormulaNutritionFactLabelType.DUAL:
      return NutritionFactLabelApiType.DUAL
    default:
      return NutritionFactLabelApiType.VERTICAL
  }
}

export const toNutritionFactLabelAgeGroup = (
  ageGroup: NutritionFactLabelApiAgeGroup
): FormulaNutritionFactLabelAgeGroup => {
  switch (ageGroup) {
    case NutritionFactLabelApiAgeGroup.ADULT:
      return FormulaNutritionFactLabelAgeGroup.ADULT
    case NutritionFactLabelApiAgeGroup.INFANT:
      return FormulaNutritionFactLabelAgeGroup.INFANT
    case NutritionFactLabelApiAgeGroup.CHILDREN_LESS_THAN_FOUR:
      return FormulaNutritionFactLabelAgeGroup.CHILDREN_LESS_THAN_FOUR
    case NutritionFactLabelApiAgeGroup.LACTACTING_WOMEN:
      return FormulaNutritionFactLabelAgeGroup.LACTACTING_WOMEN
    case NutritionFactLabelApiAgeGroup.PREGNANT_WOMEN:
      return FormulaNutritionFactLabelAgeGroup.PREGNANT_WOMEN
  }
}

export const fromNutritionFactLabelAgeGroup = (
  ageGroup: FormulaNutritionFactLabelAgeGroup
): NutritionFactLabelApiAgeGroup => {
  switch (ageGroup) {
    case FormulaNutritionFactLabelAgeGroup.ADULT:
      return NutritionFactLabelApiAgeGroup.ADULT
    case FormulaNutritionFactLabelAgeGroup.INFANT:
      return NutritionFactLabelApiAgeGroup.INFANT
    case FormulaNutritionFactLabelAgeGroup.CHILDREN_LESS_THAN_FOUR:
      return NutritionFactLabelApiAgeGroup.CHILDREN_LESS_THAN_FOUR
    case FormulaNutritionFactLabelAgeGroup.LACTACTING_WOMEN:
      return NutritionFactLabelApiAgeGroup.LACTACTING_WOMEN
    case FormulaNutritionFactLabelAgeGroup.PREGNANT_WOMEN:
      return NutritionFactLabelApiAgeGroup.PREGNANT_WOMEN
  }
}

export const toBasicFormulaTargetCategory = (
  response: BasicFormulaTargetCategoryApiResponse
): BasicFormulaTargetCategory => {
  return {
    ...response
  }
}

export const toOperationExecutionErrorType = (
  errorType: OperationExecutionErrorTypeApiResponse
): OperationExecutionErrorType => {
  switch (errorType) {
    case OperationExecutionErrorTypeApiResponse.COMPARISON_FAILURE:
      return OperationExecutionErrorType.COMPARISON_FAILURE
    case OperationExecutionErrorTypeApiResponse.DATA_NOT_FOUND:
      return OperationExecutionErrorType.DATA_NOT_FOUND
    case OperationExecutionErrorTypeApiResponse.LOGIC_ERROR:
      return OperationExecutionErrorType.LOGIC_ERROR
    case OperationExecutionErrorTypeApiResponse.OTHER:
      return OperationExecutionErrorType.OTHER
    default:
      return OperationExecutionErrorType.OTHER
  }
}

export const toOperationExecutionError = (
  response: OperationExecutionErrorApiResponse
): OperationExecutionError => {
  return {
    ...response,
    errorType: toOperationExecutionErrorType(response.errorType)
  }
}

export const toOperationExecutionResult = (
  response: OperationExecutionResultApiResponse
): OperationExecutionResult => {
  return {
    ...response,
    operation: toOperation(response.operation),
    error: response.error
      ? toOperationExecutionError(response.error)
      : undefined
  }
}

export const toTruthSetExecutionResult = (
  response: TruthSetExecutionResultApiResponse
): TruthSetExecutionResult => {
  return {
    ...response,
    truthSetOperationsExecutionResults:
      response.truthSetOperationsExecutionResults.map(
        (operationExecutionResult) =>
          toOperationExecutionResult(operationExecutionResult)
      )
  }
}

export const toFormulaTarget = (
  response: FormulaTargetApiResponse
): FormulaTarget => {
  return {
    ...response,
    formula: toBasicFormula(response.formula),
    targetDefinition: toBasicTarget(response.targetDefinition),
    targetDefinitionCategory: response.targetDefinitionCategory
      ? toBasicFormulaTargetCategory(response.targetDefinitionCategory)
      : undefined,
    targetExecutionTracePerTruthSet: response.targetExecutionTracePerTruthSet
      ? response.targetExecutionTracePerTruthSet.map(
        (truthSetExecutionResult) =>
          toTruthSetExecutionResult(truthSetExecutionResult)
      )
      : undefined
  }
}

export const toFormulaTargets = (
  response: FormulaTargetApiResponse[]
): FormulaTarget[] => {
  return response.map((formulaTarget) => {
    return toFormulaTarget(formulaTarget)
  })
}
