import styled from 'styled-components'
import {
  FlexContainerRowSpaced,
  NutritionLabelBox
} from '../components/NutritionLabelStyles'

export const NutritionLabelTabularBox = styled(NutritionLabelBox)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  min-width: 900px;
`

export const NutritionFactDetailContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  max-width: 30%;
`

export const NutrientsBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 58%;
`

export const FooterNoteContainer = styled.div`
  padding-top: 14px;
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  max-width: 12%;
`

export const AmountPerServingContainer = styled(FlexContainerRowSpaced)`
  align-items: flex-end;
`

export const NurientContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`

export const NutrientsTableHeaderContainer = styled(FlexContainerRowSpaced)``

export const NutrientsTableBottomContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`

export const NutrientTableContainer = styled.div`
  flex: 1;
`
